/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import axios from "axios";

type LogInData = {
  email: string;
  password: string;
  history: any;
};
type UpdateAccountDetailsData = {
  userFullName: string;
  emailAddress: string;
  phoneNumber: string;
};
type UpdatePasswordData = {
  emailAddress: string;
  username: any;
  userId: any;
  password: string;
  oldPassword: string;
  passwordVerify: string;
  isReset: boolean;
  history: any;
};
type ChangePasswordData = {
  oldPassword: string;
  newPassword: string;
  newPasswordVerify: string;
};

type ShopifyAppConfig = {
  passedStoreName?: any;
  redirectToUrl: boolean;
};
export const getSessionToken: any = createAsyncThunk(
  "auth/getSessionToken",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/rest/s1/fp/moquiSessionToken");
      console.log("session token", response);

      if (response.status === 200) {
        localStorage.setItem(
          "moquiSessionToken",
          response.data.moquiSessionToken
        );
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser: any = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password, history }: LogInData, thunkAPI) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post(
        "/rest/s1/fp/login",
        {
          username: email,
          password,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("login data", response);
      if (response.status === 200) {
        localStorage.setItem(
          "moquiSessionToken",
          response.headers.moquisessiontoken
        );
        if (!response.data.forcePasswordChange) {
          localStorage.setItem(
            "loggedInToken",
            response.headers.moquisessiontoken
          );
        }
        if (response.data.userId) {
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("accountId", response.data.userId);
        }
        if (response.data.productStoreId) {
          localStorage.setItem("productStoreId", response.data.productStoreId);
        }
        if (response.data.organizationPartyId) {
          localStorage.setItem(
            "organizationPartyId",
            response.data.organizationPartyId
          );
        }
        if (response.data.customerInfo.partyId) {
          localStorage.setItem("partyId", response.data.customerInfo.partyId);
        }
        const userObj = {
          name:
            response.data.customerInfo.firstName &&
            response.data.customerInfo.lastName
              ? `${response.data.customerInfo.firstName} ${response.data.customerInfo.lastName}`
              : response.data.customerInfo.firstName
              ? response.data.customerInfo.firstName
              : "",
          email: response.data.customerInfo.emailAddress
            ? response.data.customerInfo.emailAddress
            : "",
          phone: response.data.customerInfo.phoneNumber
            ? response.data.customerInfo.phoneNumber
            : "",
        };
        localStorage.setItem("setUserInfo", JSON.stringify(userObj));
        // const groupList = [{ userGroupId: 'SmartShipCustomer' }]
        // groupList.forEach((item: any) => {
        //   if (item.userGroupId === 'SmartShipCustomer') {
        //     localStorage.setItem('accountType', 'owner')
        //   } else if (item.userGroupId === 'SmartShipAccountUser') {
        //     localStorage.setItem('accountType', 'user')
        //   }
        // })
        response.data &&
          response.data.userGroupMemberList &&
          response.data.userGroupMemberList.length > 0 &&
          response.data.userGroupMemberList.forEach((item: any) => {
            if (item.userGroupId === "FPAppOwner") {
              localStorage.setItem("accountType", "owner");
            } else if (item.userGroupId === "FPAppUser") {
              localStorage.setItem("accountType", "user");
            }
          });
        if (response.data.customerInfo) {
          const customerData = {
            firstName: response.data.customerInfo.firstName,
            lastName: response.data.customerInfo.lastName,
            email: response.data.customerInfo.emailAddress,
            phone: response.data.customerInfo.contactNumber,
          };
          localStorage.setItem("customerInfo", JSON.stringify(customerData));
        }
        let redirectTo = "/";
        localStorage.setItem("currScreen", "dashboard");
        localStorage.setItem("isNavOpen", "true");
        const currPath = localStorage.getItem("currentPath");
        if (currPath) {
          redirectTo = currPath;
        }
        history.push(redirectTo);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const logout: any = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/logout", {
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("logout resp", response);
      if (response.status === 200) {
        localStorage.clear();
        // await thunkAPI.dispatch(ignoreSidebarAPICall())
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getShopifyAppConfig: any = createAsyncThunk(
  "auth/getShopifyAppConfig",
  async ({ passedStoreName, redirectToUrl }: ShopifyAppConfig, thunkAPI) => {
    try {
      let params: any = {};
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      // if (localStorage.getItem("organizationPartyId")) {
      //   params.organizationPartyId = localStorage.getItem(
      //     "organizationPartyId"
      //   );
      // } else {
      //   params.storeName = passedStoreName;
      // }
      params.storeName = passedStoreName;
      const response = await axios.get("/rest/s1/fp/getShopifyAppConfig", {
        params,
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("getShopifyAppConfig data", response);
      if (response.status === 200) {
        if (redirectToUrl) {
          window.location.href = `https://${response.data?.storeName}.myshopify.com/admin/oauth/authorize?client_id=${response.data?.apiKey}&scope=${response.data?.scope}&redirect_uri=${response.data?.redirectionUrl}&state=${response.data?.accountId}`;
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getDashboardReports: any = createAsyncThunk(
  "auth/getDashboardReports",
  async (
    {
      setDashboardDetails,
      monthStartDate,
      monthEndDate,
    }: { setDashboardDetails: any; monthStartDate: any; monthEndDate: any },
    thunkAPI
  ) => {
    try {
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/dashboard/details", {
        params: {
          organizationPartyId: organizationPartyId,
          monthStartDate,
          monthEndDate,
        },
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("dashboard details data", response);
      if (response.status === 200) {
        setDashboardDetails(response.data);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getGuestToken: any = createAsyncThunk(
  "auth/getGuestToken",
  async ({ setData }: { setData: any }, thunkAPI) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/organization/getGuestToken`,
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        console.log("get guest token", response);
        setData(response.data);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getIncomingShipmentList: any = createAsyncThunk(
  "auth/getIncomingShipmentList",
  async (
    {
      orderByFields,
      setIncomingshipmentList,
      setRecordCount,
      setIncomingShipmentsPageMaxIndex,
      pageIndex,
      pageSize=10,
      searchQuery
    }: {
      orderByFields: any;
      setIncomingshipmentList: any;
      setRecordCount: Function;
      setIncomingShipmentsPageMaxIndex: Function;
      pageIndex: any;
      pageSize?:any
      searchQuery:any
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/incomingShipments?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        {
          params: {
            searchQuery,
            orderByFields,
            organizationPartyId: localStorage.getItem("organizationPartyId"),
          },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Get incomingShipment data", response);
      if (response.status === 200) {
        setIncomingshipmentList(response.data.incomingShipments);
        if (
          response.data.incomingShipmentsCount >
          10 * response.data.incomingShipmentsPageMaxIndex
        ) {
          setIncomingShipmentsPageMaxIndex(
            response.data.incomingShipmentsPageMaxIndex + 1
          );
        } else {
          setIncomingShipmentsPageMaxIndex(
            response.data.incomingShipmentsPageMaxIndex
          );
        }
        setRecordCount(response.data.incomingShipmentsCount);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getOrderListing: any = createAsyncThunk(
  "auth/getOrderListing ",
  async (
    {
      setgetOrderListingDetail,
      setGetBulkOrderListingDetail,
      setMaxPageIndex,
      orderByFields,
      setRecordCount,
      pageIndex,
      placedDate_from,
      placedDate_thru,
      statusId,
      facilityId,
      queryString,
      onlyShipped,
      appliedFilters,
      isReturnedOrders,
      pageSize=10
    }: {
      setgetOrderListingDetail: Function;
      setGetBulkOrderListingDetail: Function;
      setMaxPageIndex: Function;
      orderByFields?: any;
      setRecordCount: Function;
      pageIndex: any;
      placedDate_from?: any;
      placedDate_thru?: any;
      statusId?: any;
      facilityId?: any;
      queryString?: any;
      onlyShipped: boolean;
      appliedFilters: any;
      isReturnedOrders?: any;
      pageSize?:any
    },
    thunkAPI
  ) => {
    try {
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const token: any = localStorage.getItem("moquiSessionToken");
      let paramsToPass: any = {
        orderByFields,
        placedDate_from,
        placedDate_thru,
        statusId,
        facilityId,
        isReturnedOrders,
        indexName:"fp_orders_new",
        documentType:"FPOrdersNew"
      };
      if (queryString) {
        if (appliedFilters) {
          const filtersArr = appliedFilters.split("&");
          const index = filtersArr.findIndex((item: any) =>
            item.includes("queryString")
          );
          if (index > -1) {
            let extractedQuery = filtersArr[index];
            extractedQuery += ` AND ${queryString}`;
            filtersArr[index] = extractedQuery;
            const joinedFilters = filtersArr.join("&");
            appliedFilters = joinedFilters;
          } else {
            appliedFilters += `&queryString=${queryString}`;
          }
        } else {
          appliedFilters += `queryString=${queryString}`;
        }
      }
      if (onlyShipped) {
        paramsToPass["onlyShipped"] = true;
      }
      let url = `/rest/s1/fp/order?organizationPartyId=${organizationPartyId}&pageSize=${pageSize}&pageIndex=${pageIndex}`;
      let urlWithoutPaginate = `${url}&isPaginate=false`;
      if (appliedFilters) {
        url = `/rest/s1/fp/order?organizationPartyId=${organizationPartyId}&pageSize=${pageSize}&pageIndex=${pageIndex}&${appliedFilters}`;
        urlWithoutPaginate = `${url}&isPaginate=false`;
      }
      const response = await axios.get(url, {
        params: paramsToPass,
        headers: {
          moquiSessionToken: token,
        },
      });
      const responseWithoutPaginate = await axios.get(urlWithoutPaginate, {
        params: paramsToPass,
        headers: {
          moquiSessionToken: token,
        },
      });
      if (responseWithoutPaginate.status === 200) {
        setGetBulkOrderListingDetail(responseWithoutPaginate.data.orderList);
      } else {
        return thunkAPI.rejectWithValue(responseWithoutPaginate);
      }
      if (response.status === 200) {
        setgetOrderListingDetail(response.data.orderList);
        setRecordCount(response.data.orderListCount);
        setMaxPageIndex(response.data.orderListPageMaxIndex + 1);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getOrderDetail: any = createAsyncThunk(
  "auth/getOrderDetail",
  async (
    {
      setOrderDetailData,
      setCustomerContact,
      setCurrStatus,
      setStatusOnSave,
      setTotalQty,
      id,
      setGrandTotal,
      setShipping,
      setDiscount,
      setSalesTax,
      setSubTotal,
      setPaymentList,
      setShipmentDetails,
      setShipmentGridDetail,
      setCustomerShippingInfo,
      setShipmentItemDetail,
      setOrderItems,
      setShippingMethods,
      setIsFirstTimeRendering,
      handleIdValidity
    }: {
      setOrderDetailData: Function;
      setCustomerContact: Function;
      setTotalQty: any;
      setCurrStatus: any;
      setStatusOnSave: any;
      id: any;
      setGrandTotal: Function;
      setShipping: Function;
      setDiscount: Function;
      setSalesTax: Function;
      setSubTotal: Function;
      setPaymentList: any;
      setShipmentDetails: Function;
      setShipmentGridDetail: Function;
      setCustomerShippingInfo: any;
      setShipmentItemDetail: Function;
      setOrderItems: Function;
      setShippingMethods:Function
      setIsFirstTimeRendering:Function
      handleIdValidity:Function
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(`/rest/s1/fp/order/${id}`, {
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });

      if (response.status === 200) {
        setOrderDetailData(response.data);
        let shipmentData: any = [];
        let orderItemsList: any = [];
        // let customerPartyId = "";
        if (
          response.data &&
          response.data.order &&
          response.data.order.customerDetails &&
          response.data.order.customerDetails.length > 0
        ) {
          // if (response.data.order.customerDetails[0]?.partyId) {
          //   customerPartyId = response.data.order.customerDetails[0]?.partyId;
          // }
        }
        setCurrStatus(response.data?.order?.statusId);
        setStatusOnSave(response.data?.order?.statusId);
        response.data.order?.parts.map((item: any) => {
          console.log(
            item?.partOrderItemList.filter(
              (item: any) => item?.itemTypeEnumId === "ItemProduct"
            )
          );
          let itemsQtyCount: any = 0;
          item?.partOrderItemList &&
            item?.partOrderItemList.length > 0 &&
            // item?.partOrderItemList.itemTypeEnumId === "ItemProduct" &&
            item?.partOrderItemList
              .filter((item: any) => item?.itemTypeEnumId === "ItemProduct")
              .forEach((itemList: any) => {
                // setTotalQty((prev: any) => prev + itemList?.quantity);
                itemsQtyCount += itemList?.quantity;
              });
          setTotalQty(itemsQtyCount);
          item?.partShipmentItemSourceList.map((itemList: any) => {
            shipmentData.push(itemList);
            return null;
          });
          if (item?.partOrderItemList?.length > 0) {
            orderItemsList = [
              ...orderItemsList,
              ...(item.partOrderItemList?.filter((partItem: any) => partItem?.itemTypeEnumId === "ItemProduct").map((filteredItem: any) => ({
                  ...filteredItem,
                  returnInfo: response.data?.order?.returnInfo?.filter((pro: any) => pro?.productId == filteredItem?.productId) || []
              })) || [])
          ];
          }
          return null;
        });
        setShipmentItemDetail(shipmentData);

const groupedOrderItems = orderItemsList.reduce((acc: any, item: any) => {
  const { productId, quantity = 0, componentProductList = [],orderItemSeqId } = item;
 const key = `${productId}_${orderItemSeqId}`;
  if (!acc[key]) {
    acc[key] = {
      ...item,
      quantity: 0,
      componentProductList: [], 
    };
  }

  acc[key].quantity += quantity;

  componentProductList.forEach((comp: any) => {
    const {
      componentProductId,
      orderItemSeqId: compSeq,
      assetId,
      quantity: compQty = 0,
      quantityNotIssued = 0,
    } = comp;

    const existingComp = acc[key].componentProductList.find(
      (c: any) =>
        c.componentProductId === componentProductId &&
        c.orderItemSeqId === compSeq &&
        c.assetId === assetId
    );

    if (existingComp) {
      existingComp.quantity += compQty;
      existingComp.quantityNotIssued += quantityNotIssued;
    } else {
      acc[key].componentProductList.push({ ...comp });
    }
  });

  return acc;
}, {} as Record<string, any>);

const finalGroupedArray = Object.values(groupedOrderItems);
const updatedArray = finalGroupedArray.map((item: any) => {
  if (!item.componentProductList) {
    return item;
  }

  const groupedComponentsObj = item.componentProductList.reduce(
    (acc: Record<string, any>, comp: any) => {
      const {
        componentProductId,
        quantity = 0,
        quantityNotIssued = 0,
      } = comp;

      const key = `${componentProductId}`;

      if (!acc[key]) {
        acc[key] = { ...comp };
      } else {
        acc[key].quantity += quantity;
        acc[key].quantityNotIssued += quantityNotIssued;
      }
      return acc;
    },
    {}
  );

  const groupedComponentsArr = Object.values(groupedComponentsObj);

  return {
    ...item,
    componentProductList: groupedComponentsArr,
  };
});
setOrderItems(updatedArray);

        let shipmentGridData: any = [];
        response.data.order?.parts.map((gridData: any) => {
          gridData.shipments.map((item: any) => {
            shipmentGridData.push(item);
            return null;
          });
          return null;
        });
        setShipmentGridDetail(shipmentGridData);
        setCustomerContact(response.data.order.customerDetails[0]);
        setGrandTotal(response.data.order.grandTotal);
        setShipmentDetails(response.data.order.parts[0]);

        //For Subtotal
        let itemSubTotal: any = [];
        response.data.order.parts.map((item: any) => {
          item.partOrderItemList.map((ship: any) => {
            if (ship.itemTypeEnumId === "ItemProduct") {
              itemSubTotal.push(ship);
            }
            return null;
          });
          return null;
        });
        const subtotalResult = itemSubTotal.map((item: any) => {
          const sum = item.unitAmount * item.quantity;
          return sum;
        });
        const subTotal = subtotalResult.reduce(
          (partial_sum: any, a: any) => partial_sum + a,
          0
        );
        const len = subtotalResult.length;
        setSubTotal([
          { name: "sum", value: subTotal.toFixed(2) },
          { name: "length", value: len },
        ]);
        //For Shipping
        let itemShipmentTotal: any = [];
        response.data.order.parts.map((item: any) => {
          item.partOrderItemList.map((ship: any) => {
            if (ship.itemTypeEnumId === "ItemShipping")
              itemShipmentTotal.push(ship);
            return null;
          });
          return null;
        });
        itemShipmentTotal.map((item: any) => {
          const sum = item.unitAmount * item.quantity;
          setShipping([
            { name: "sum", value: Number(sum).toFixed(2) },
            { name: "description", value: item.itemDescription },
          ]);
          return null;
        });

        // For Discount
        let discount = 0;
        response.data.order.parts.map((item: any) => {
          item.partOrderItemList.forEach((ship: any) => {
            if (ship.itemTypeEnumId === "ItemDiscount") {
              const unitAmount = Math.abs(ship.unitAmount);
              discount += unitAmount * ship.quantity;
            }
          });
          return null;
        });
        setDiscount(discount);

        //For tax
        let itemSalesTotal: any = [];
        response.data.order.parts.map((item: any) => {
          item.partOrderItemList.map((ship: any) => {
            if (ship.itemTypeEnumId === "ItemSalesTax")
              itemSalesTotal.push(ship);
            return null;
          });
          return null;
        });
        const timesalestaxResult = itemSalesTotal.map((item: any) => {
          const sum = item.unitAmount * item.quantity;
          return sum;
        });
        const itemDesc = itemSalesTotal.map((item: any) => {
          const resultDescription = item.itemDescription;
          return resultDescription;
        });
        const data: any = [];
        var helperVariable = 0;
        for (var i = 0; i < timesalestaxResult.length; i = i + 2) {
          helperVariable = helperVariable + timesalestaxResult[i];
        }
        data.push(helperVariable);
        var variable = 0;
        for (var j = 1; j < timesalestaxResult.length; j = j + 2) {
          variable = variable + timesalestaxResult[j];
        }
        data.push(variable);
        const uniqueNames = itemDesc.filter(
          (val: any, id: any, array: any) => array.indexOf(val) === id
        );
        setSalesTax([
          { name: "sum", value: data },
          { name: "description", value: "" },
          { name: "subtitle", value: uniqueNames },
        ]);

        //For payment Detail

        const status =
          response.data.order.parts[0]?.partPaymentInfoList[0]?.statusItem
            .description;
        const Amount =
          response.data.order.parts[0]?.partPaymentInfoList[0]?.partPayment
            .amount;
        const paymentRefNum =
          response.data.order.parts[0]?.partPaymentInfoList[0]?.partPayment
            .paymentRefNum;
        const effectiveDate =
          response.data.order.parts[0]?.partPaymentInfoList[0]?.partPayment
            .effectiveDate;

        setPaymentList([
          {
            name: "sum",
            value: effectiveDate,
          },
          {
            name: "refNum",
            value: paymentRefNum,
          },
          {
            name: "amount",
            value: Amount,
          },
          { name: "status", value: status },
        ]);
        // await thunkAPI.dispatch(
        //   getCustomerShippingAddress({ setCustomerShippingInfo, customerPartyId })
        // )
        await thunkAPI.dispatch(
          getAllShippingMethods({
            setShippingMethods,
            productStoreId: response?.data?.order?.productStoreId,
          })
        );
        setIsFirstTimeRendering(false);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      handleIdValidity(false)
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getIncomingShipmentDetails: any = createAsyncThunk(
  "auth/getIncomingShipmentDetails",
  async (
    {
      orderByFields,
      setVendorDetail,
      setVisualParts,
      setShipmentItemsList,
      setIncomingShipmentDetails,
      setCurrentEntryDate,
      id,
      setIsFirstTimeRender,
      handleIdValidity,
    }: {
      orderByFields: any;
      setVendorDetail: any;
      setVisualParts: any;
      setShipmentItemsList: any;
      setIncomingShipmentDetails: Function;
      setCurrentEntryDate: Function;
      setIsFirstTimeRender?: any;
      id: any;
      handleIdValidity:Function
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(`/rest/s1/fp/incomingShipments/${id}`, {
        params: {
          orderByFields,
        },
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("get incoming shipment Details", response);
      if (response.status === 200) {
        let states = response?.data?.vendorContactAndDetails?.[0]?.countryGeoId;
        if (states) {
          await thunkAPI.dispatch(getGeoStates(states));
        }
        setVendorDetail(response?.data?.vendorContactAndDetails?.[0]);
        setIncomingShipmentDetails(response.data);
        if (response.data && response.data.entryDate) {
          setCurrentEntryDate(response.data.entryDate);
        }
        setShipmentItemsList(response.data.shipmentItems);
        let totalReceivedQuantity: any = 0;
        let totalRejectedQuantity: any = 0;
        let totalRemainingQuantity: any = 0;
        let totalExpectedQuantity: any = 0;
        let receivedQtyPercentage: any = "";
        let rejectedQtyPercentage: any = "";
        let remainingQtyPercentage: any = "";
        response.data &&
          response.data.shipmentItems &&
          response.data.shipmentItems.forEach((item: any) => {
            totalReceivedQuantity += item.receivedQuantity
              ? item.receivedQuantity
              : 0;
            totalRejectedQuantity += item.rejectedQuantity
              ? item.rejectedQuantity
              : 0;
            totalRemainingQuantity += item.remainingQuantity
              ? item.remainingQuantity
              : 0;
            totalExpectedQuantity += item.expectedQuantity
              ? item.expectedQuantity
              : 0;
          });
        receivedQtyPercentage =
          (totalReceivedQuantity / totalExpectedQuantity) * 100;
        receivedQtyPercentage =
          Math.round(receivedQtyPercentage * 100) / 100 + "%";
        rejectedQtyPercentage =
          (totalRejectedQuantity / totalExpectedQuantity) * 100;
        rejectedQtyPercentage =
          Math.round(rejectedQtyPercentage * 100) / 100 + "%";
        remainingQtyPercentage =
          (totalRemainingQuantity / totalExpectedQuantity) * 100;
        remainingQtyPercentage =
          Math.round(remainingQtyPercentage * 100) / 100 + "%";
        const valuesToSet = [
          {
            percentage: receivedQtyPercentage,
            value: totalReceivedQuantity,
            color: "#57bb54",
          },
          {
            percentage: rejectedQtyPercentage,
            value: totalRejectedQuantity,
            color: "#e11f20",
          },
          {
            percentage: remainingQtyPercentage,
            value: totalRemainingQuantity,
            color: "#b3b3b3",
          },
        ];
        setVisualParts(valuesToSet);
        setIsFirstTimeRender(false);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      handleIdValidity(false)
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getAllFacilities: any = createAsyncThunk(
  "auth/getAllFacilities",
  async ({ setFacilitiesList }: { setFacilitiesList: Function }, thunkAPI) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let organizationPartyId = localStorage.getItem("organizationPartyId");
      const response = await axios.get(
        `/rest/s1/fp/facility/facilitiesList?ownerPartyId=${organizationPartyId}`,
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        console.log("facilitiesList", response);
        let mappedArray: any = [];
        response.data.facilityList.forEach((item: any) => {
          mappedArray.push({
            key: item.facilityId,
            value: item.facilityName ? item.facilityName : item.facilityId,
          });
        });
        setFacilitiesList(mappedArray);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateVendorDetatil: any = createAsyncThunk(
  "auth/updateVendorDetatil",
  async (
    {
      data,
      shipmentId,
      ownerPartyId,
      partyId,
      postalContactMechId,
      emailContactMechId,
      telecomContactMechId,
      fetchData,
    }: {
      data: any;
      shipmentId: any;
      ownerPartyId: any;
      partyId: any;
      postalContactMechId: any;
      emailContactMechId: any;
      telecomContactMechId: any;
      fetchData: Function;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post(
        `/rest/s1/fp/organization/${ownerPartyId}/address`,
        {
          ...data,
          shipmentId,
          ownerPartyId,
          partyId,
          postalContactMechId,
          emailContactMechId,
          telecomContactMechId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("updated account details data", response);
      if (response.status === 200) {
        if (response.data?.messages) {
          notification["error"]({
            message: "Error",
            description: response.data?.messages,
          });
        } else {
          notification["success"]({
            message: "Success",
            description: "Vendor Detail Updated Sucessfully",
          });
        }
        fetchData();
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateVendorFacility: any = createAsyncThunk(
  "auth/updateVendorFacility",
  async (
    {
      shipmentId,
      destinationFacilityId,
    }: { shipmentId: any; destinationFacilityId: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        `/rest/s1/fp/incomingShipments/${shipmentId}`,
        {
          shipmentId,
          destinationFacilityId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("updated account details data", response);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateVendorFormParty: any = createAsyncThunk(
  "auth/updateVendorFormParty",
  async (
    { shipmentId, fromPartyId }: { shipmentId: any; fromPartyId: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        `/rest/s1/fp/incomingShipments/${shipmentId}`,
        {
          shipmentId,
          fromPartyId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("updated account details data", response);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getOrgnizationPartyId: any = createAsyncThunk(
  "auth/getOrgnizationPartyId",
  async ({ setOwnerPartyIdList }: { setOwnerPartyIdList: any }, thunkAPI) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/organization", {
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      if (response.status === 200) {
        const mappedArray = response.data.organizationList.map((item: any) => {
          return {
            key: `${item?.partyId}`,
            value: `${item?.organizationName}`,
          };
        });
        setOwnerPartyIdList(mappedArray);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getStatusList: any = createAsyncThunk(
  "auth/getStatusList",
  async (
    { setStatus, statusTypeId }: { setStatus: Function; statusTypeId: any },
    thunkAPI
  ) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/status", {
        params: {
          statusTypeId,
        },
        headers: {
          moquiSessionToken: token,
        },
      });

      console.log("get status data", response);
      if (response.status === 200) {
        const mappedArray = response.data.statusList.map((item: any) => {
          return {
            key: item.statusId,
            value: item.description,
          };
        });
        setStatus(mappedArray);
        return mappedArray;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getEnumTypeList: any = createAsyncThunk(
  "auth/getEnumTypeList",
  async (
    { setEnumType, enumTypeId }: { setEnumType: Function; enumTypeId: string },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/enum", {
        params: {
          enumTypeId,
        },
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("getEnumTypeList data", response);
      if (response.status === 200) {
        const mappedArray = response.data.enumTypeList.map((item: any) => {
          if (enumTypeId === "ReturnResponse") {
            if (item.description === "Refund") {
              return {
                key: item.enumId,
                value: "Original Payment Method",
              };
            } else if (item.description === "Credit") {
              return {
                key: item.enumId,
                value: "Store Credit",
              };
            } else if (item.description === "Replace") {
              return {
                key: item.enumId,
                value: "Exchange",
              };
            }
            return {
              key: item.enumId,
              value: item.description,
            };
          } else {
            return {
              key: item.enumId,
              value: item.description,
            };
          }
        });

        setEnumType(mappedArray);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getOrderAgingReport: any = createAsyncThunk(
  "auth/getOrderAgingReport",
  async (setOrderAgingTableList: Function, thunkAPI) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const response = await axios.get(
        `/rest/s1/fp/order/agingReport?organizationPartyId=${organizationPartyId}`,
        {
          params: {},
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Order aging report", response);
      if (response.status === 200) {
        setOrderAgingTableList(response.data);

        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getSimpleInventoryReport: any = createAsyncThunk(
  "auth/getSimpleInventoryReport",
  async (
    {
      setSimpleInventoryReport,
      setListCount,
      setInventoryListPageMaxIndex,
      pageIndex,
      searchQuery,
      setAllSimpleInventoryReport,
      isPaginate = true,
      orderByFields,
      appliedFilters,
      isSearch,
      pageSize=10
    }: {
      setSimpleInventoryReport: Function;
      setListCount: Function;
      setInventoryListPageMaxIndex: Function;
      pageIndex: any;
      searchQuery:any
      setAllSimpleInventoryReport: Function;
      isPaginate?: Boolean;
      orderByFields: any;
      appliedFilters: any;
      isSearch?:any
      pageSize?:any
    },
    thunkAPI
  ) => {
    try {
      let paramsToPass: any = {
        organizationPartyId: localStorage.getItem("organizationPartyId"),
      };

      let url: any;
      if (!isPaginate) {
        url = `/rest/s1/fp/simpleInventoryReport?isPaginate=${isPaginate}`;
        paramsToPass = {
          ...paramsToPass,
          orderByFields,
        };
      } else {
        url = `/rest/s1/fp/simpleInventoryReport?pageSize=${pageSize}&pageIndex=${pageIndex}`;
        paramsToPass = {
          ...paramsToPass,
          orderByFields,
          searchQuery
        };
      }

      if (appliedFilters && isPaginate&&isSearch) {
        url = `/rest/s1/fp/simpleInventoryReport?pageIndex=${pageIndex}&pageSize=${pageSize}&${appliedFilters}&isSearch=true&isPaginate=false`;
      }
      if(appliedFilters && !isPaginate&&!isSearch){
        url = `/rest/s1/fp/simpleInventoryReport?pageIndex=${pageIndex}&pageSize=${pageSize}&${appliedFilters}&isPaginate=false`;
      }

      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(url, {
        params: paramsToPass,
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      if (response.status === 200 && isPaginate === false) {
        setAllSimpleInventoryReport(response?.data?.inventoryList);
      }
      if (response.status === 200 && isSearch) {
        if (response.data && response.data.inventoryList) {
          setSimpleInventoryReport(response.data.inventoryList);
        }
        if (
          response?.data?.inventoryListCount >
          10 * response?.data?.inventoryListPageMaxIndex
        ) {
          setInventoryListPageMaxIndex(
            response?.data?.inventoryListPageMaxIndex + 1
          );
        } else {
          setInventoryListPageMaxIndex(
            response?.data?.inventoryListPageMaxIndex
          );
        }
        setListCount(response?.data?.inventoryListCount);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUserInfo: any = createAsyncThunk(
  "auth/getUserInfo",
  async (_, thunkAPI) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/user", {
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("get user info data", response);
      if (response.status === 200) {
        const userObj = {
          name: response.data.userFullName
            ? response.data.userFullName
            : response.data.firstName && response.data.lastName
            ? `${response.data.firstName} ${response.data.lastName}`
            : response.data.firstName
            ? response.data.firstName
            : "",
          email: response.data.emailAddress ? response.data.emailAddress : "",
          phone: response.data.phoneNumber ? response.data.phoneNumber : "",
          address1: response.data.address1 ? response.data.address1 : "",
          address2: response.data.address2 ? response.data.address2 : "",
          city: response.data.city ? response.data.city : "",
          postalCode: response.data.postalCode ? response.data.postalCode : "",
          stateProvinceGeoId: response.data.stateProvinceGeoId
            ? response.data.stateProvinceGeoId
            : "",
          countryGeoId: response.data.countryGeoId
            ? response.data.countryGeoId
            : "",
        };
        //setUserInfo(userObj)
        localStorage.setItem("setUserInfo", JSON.stringify(userObj));
        localStorage.setItem("currCountryGeoId", userObj.countryGeoId);
        localStorage.setItem("currStateGeoId", userObj.stateProvinceGeoId);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateAccountDetails: any = createAsyncThunk(
  "auth/updateAccountDetails",
  async (
    { userFullName, emailAddress, phoneNumber }: UpdateAccountDetailsData,
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const userId = localStorage.getItem("userId");
      const response = await axios.put(
        `/rest/s1/fp/user/${userId}`,
        {
          userId,
          userFullName,
          emailAddress,
          phoneNumber,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("updated account details data", response);
      if (response.status === 200) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updatePassword: any = createAsyncThunk(
  "auth/updatePassword",
  async (
    {
      emailAddress,
      password,
      username,
      userId,
      oldPassword,
      passwordVerify,
      isReset,
      history,
    }: UpdatePasswordData,
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let url = `/rest/s1/fp/user/setPassword`;
      console.log("url", url);
      const response = await axios.post(
        url,
        {
          emailAddress,
          userId: userId,
          username: username,
          newPassword: password,
          oldPassword,
          newPasswordVerify: passwordVerify,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("set password data", response);

      if (response.status === 200) {
        if (response.data?.updateSuccessful) {
          notification["success"]({
            message: "Success",
            description: "Password set successfully",
          });
          history.push("/login");
        } else {
          notification["error"]({
            message: "Error",
            description: response.data?.messages,
          });
        }
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const changePassword: any = createAsyncThunk(
  "auth/changePassword",
  async (
    { oldPassword, newPassword, newPasswordVerify }: ChangePasswordData,
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const userId = localStorage.getItem("userId");
      const response = await axios.put(
        `/rest/s1/fp/user/${userId}/changePassword`,
        {
          userId,
          oldPassword,
          newPassword,
          newPasswordVerify,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("change password data", response);

      if (response.status === 200) {
        if (response.data.updateSuccessful) {
          notification["success"]({
            message: "Success",
            description: "Password changed successfully!",
          });
        } else {
          notification["error"]({
            message: "Error",
            description: response.data.messages,
          });
        }
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const resetPasswordEmail: any = createAsyncThunk(
  "auth/resetPasswordEmail",
  async (
    {
      emailAddress,
      setNextPage,
    }: { emailAddress: string; setNextPage: Function },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post(
        "/rest/s1/fp/user/sendResetPasswordEmail",
        {
          emailAddress,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("reset password email data", response);
      if (response.status === 200) {
        localStorage.setItem("resetPasswordEmail", emailAddress);
        setNextPage(true);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//integrated the Get SmartShip Account Info on the basis of emailAddress
export const getFpAccount: any = createAsyncThunk(
  "auth/getFpAccount",
  async (
    {
      email,
      accountId,
      setFulfillmentInfo,
    }: { email?: any; accountId?: any; setFulfillmentInfo: any },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let params = {};

      if (email) {
        params = { ...params, emailAddress: email };
      }
      if (accountId) {
        params = { ...params, accountId: accountId };
      }
      const response = await axios.get("/rest/s1/fp/appAccount", {
        params,
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("get fulfillment info-------", response);
      if (response.status === 200) {
        const userInfo = {
          name: response.data.name ? response.data.name : "",
          email: response.data.emailAddress ? response.data.emailAddress : "",
          phone: response.data.contactNumber ? response.data.contactNumber : "",
          storeName: response.data.storeName ? response.data.storeName : "",
          industry: response.data.industryTypeEnumId
            ? response.data.industryTypeEnumId
            : "",
          address1: response.data.address1 ? response.data.address1 : "",
          address2: response.data.address2 ? response.data.address2 : "",
          city: response.data.city ? response.data.city : "",
          postalCode: response.data.postalCode ? response.data.postalCode : "",
          country: response.data.countryGeoId ? response.data.countryGeoId : "",
          state: response.data.stateProvinceGeoId
            ? response.data.stateProvinceGeoId
            : "",
          userId: response.data.userId ? response.data.userId : "",
        };
        setFulfillmentInfo(userInfo);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const industryTypeList: any = createAsyncThunk(
  "auth/industryTypeList",
  async (_, thunkAPI) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/industryTypes", {
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("industry types data", response);
      if (response.status === 200) {
        const mappedArray = response.data.industryTypeList.map((item: any) => {
          return {
            key: item.enumId,
            value: item.description,
          };
        });
        return mappedArray;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//integrated the product listing REST API
export const getProductListing: any = createAsyncThunk(
  "auth/getProductListing ",
  async (
    {
      setProductList,
      setMaxPageIndex,
      orderByFields,
      setRecordCount,
      pageIndex,
      queryString,
      appliedFilters,
      isPaginate = true,
      setAmazonAws,
      pageSize=10,
      isFirstTimeRender
    }: {
      setProductList: Function;
      setMaxPageIndex: Function;
      orderByFields: any;
      setRecordCount: Function;
      pageIndex?: any;
      queryString?: any;
      appliedFilters: any;
      isPaginate?:boolean
      setAmazonAws:Function
      pageSize?:any
      isFirstTimeRender?:any
    },
    thunkAPI
  ) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      let url = `/rest/s1/fp/product`;
      if(!isPaginate){
        url = `${url}?isPaginate=false`
      }else {
        url = `${url}?pageIndex=${pageIndex}&pageSize=${pageSize}`
      }
      if (appliedFilters) {
        url = `${url}&${appliedFilters}`;
      }
      const response = await axios.get(url, {
        params: {
          orderByFields,
        },
        headers: {
          moquiSessionToken: token,
        },
      });
      if (response.status === 200) {
        if(isPaginate){
          if(isFirstTimeRender){
            await thunkAPI.dispatch(AwsUserPreference({setAmazonAws}))
          }
          setRecordCount(response.data.productListCount);
          setMaxPageIndex(response.data.productListPageMaxIndex + 1);
        }
        var filtedProductList: any = [];
        response?.data?.productList?.map((item: any) => {
          let sku = "";
          let upca = "";
          item.otherIds.forEach((item: any) => {
            if (item.productIdTypeEnumId === "PidtUpca") {
              upca = item.idValue ? item.idValue : "";
            } else if (item.productIdTypeEnumId === "PidtSku") {
              sku = item.idValue ? item.idValue : "";
            }
          });
          filtedProductList.push({
            productId: item.productId,
            pseudoId: item.pseudoId,
            productName: item.productName,
            productType: item.productType,
            productTypeEnumId: item.productTypeEnumId,
            sku: sku,
            upca: upca,
            images: item?.images,
            ...(item?.serialized==="Y" && { serialized: item.serialized })
          });
          return null;
        });
        setProductList(filtedProductList);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const AwsUserPreference: any = createAsyncThunk(
  "auth/AwsUserPreference",
  async (
    {
        setAmazonAws 
    }: {
      setAmazonAws:Function
    },
    thunkAPI
  ) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(`/rest/s1/fp/S3UserPreferences`, {
        headers: {
          moquiSessionToken: token,
        },
      });
      if (response.status === 200) {
           const awsS3UserPreferences:any=response?.data?.awsS3UserPreferences
           setAmazonAws(`${awsS3UserPreferences?.s3Url}/${awsS3UserPreferences?.bucketName}`) 
        }
      else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getProductDetails: any = createAsyncThunk(
  "auth/getProductDetails",
  async (
    {
      setProductDetails,
      id,
      setProductStatus,
      setProductType,
      setListPrice,
      setCurrentPrice,
      setAvergeCost,
      setEccnValue,
      setHtsValue,
      setCurrentProductPriceId,
      setListProductPriceId,
      handleIdValidity
    }: {
      setProductDetails: Function;
      id: string;
      setProductStatus: Function;
      setProductType: Function;
      setListPrice: Function;
      setCurrentPrice: Function;
      setAvergeCost: Function;
      setEccnValue: Function;
      setHtsValue: Function;
      setCurrentProductPriceId:Function,
      setListProductPriceId:Function,
      handleIdValidity:Function
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(`rest/s1/fp/product/${id}`, {
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("product details data", response);
      if (response.status === 200) {
        setProductDetails(response.data);
        setProductStatus(response.data.statusId);
        setProductType(response.data.productTypeEnumId);
        for (let i = 0; i < response.data.prices.length; i++) {
          if (response.data.prices[i].priceTypeEnumId === "PptAverage") {
            setAvergeCost(`$ ${response.data.prices[i].price}`);
          }
          if (response.data.prices[i].priceTypeEnumId === "PptList") {
            setListPrice(`$ ${response.data.prices[i].price}`);
            setListProductPriceId(response.data.prices[i].productPriceId);
          } else if (response.data.prices[i].priceTypeEnumId === "PptCurrent") {
            setCurrentPrice(`$ ${response.data.prices[i].price}`);
            setCurrentProductPriceId(response.data.prices[i].productPriceId);
          }
        }
        response.data?.eccnList?.forEach((item: any) => {
          if (item.productFeatureTypeEnumId === "PftEccnCode") {
            setEccnValue(item.description);
          }
        });
        response.data?.identifications?.forEach((item: any) => {
          if (item.productIdTypeEnumId === "PidtHts") {
            setHtsValue(item.description);
          }
        });
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      handleIdValidity(false)
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getFacilityList: any = createAsyncThunk(
  "auth/getFacilityList",
  async (
    {
      setFacilityList,
      setSelectedFacility,
      fromInventory,
    }: {
      setFacilityList: Function;
      setSelectedFacility?: any;
      fromInventory: any;
    },
    thunkAPI
  ) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const response = await axios.get("/rest/s1/fp/facility", {
        headers: {
          moquiSessionToken: token,
        },
        params: {
          organizationPartyId: organizationPartyId,
        },
      });

      console.log("get Facility List", response.data.facilityList);
      if (response.status === 200) {
        let mappedArray: any = [];
        response.data.facilityList.forEach((item: any) => {
          mappedArray.push({
            key: item.facilityId,
            value: item.facilityName,
          });
        });
        if (fromInventory) {
          mappedArray = mappedArray.filter(
            (val: any, index: any, self: any) =>
              index ===
              self.findIndex(
                (t: any) => t.key === val.key && t.value === val.value
              )
          );
          if (mappedArray.length > 0) {
            setSelectedFacility(mappedArray[0]["key"]);
          } else {
            setSelectedFacility("None");
          }
          setFacilityList(mappedArray);
        } else {
          setFacilityList(mappedArray);
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Integrated product Searching REST API
export const searchProductList: any = createAsyncThunk(
  "auth/searchProductList",
  async (
    {
      setSearchProdsInFocus,
      setListOfProductDetail,
      currPage,
      queryString,
      setHasMoreProds,
      listOfProductDetail = [],
    }: {
      setSearchProdsInFocus: Function;
      setListOfProductDetail: Function;
      currPage: any;
      queryString: string;
      setHasMoreProds: Function;
      listOfProductDetail: any;
    },
    thunkAPI
  ) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/search?pageIndex=${currPage}`,
        {
          params: {
            queryString,
            ownerPartyId: localStorage.getItem("activeOrgId"),
          },
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      console.log("search product listing API", response);
      if (response.status === 200) {
        setListOfProductDetail([
          ...listOfProductDetail,
          ...response.data.documentList,
        ]);
        setSearchProdsInFocus(true);
        if (10 * currPage >= response.data.documentListCount) {
          setHasMoreProds(false);
        }
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createIncomingShipment: any = createAsyncThunk(
  "auth/createIncomingShipment",
  async ({ data, history }: { data: any; history: any }, thunkAPI) => {
    try {
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post(
        "/rest/s1/fp/createIncomingShipment",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            moquiSessionToken: moquiSessionToken,
            "content-type": "multipart/form-data",
          },
          params: {
            ownerPartyId: organizationPartyId,
          },
        }
      );
      console.log("create incoming shpmnt data", response);
      if (response.status === 200) {
        if (response.data && response.data.shipmentId) {
          history.push(`/inventory/${response.data.shipmentId}`);
        }
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getGeoCountries: any = createAsyncThunk(
  "auth/getGeoCountries",
  async (_, thunkAPI) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/geos", {
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("geo countries data", response);
      if (response.status === 200) {
        const mappedArray = response.data.geoList.map((item: any) => {
          return {
            key: item.geoId,
            value: item.geoName,
          };
        });
        return mappedArray;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getGeoStates: any = createAsyncThunk(
  "auth/getGeoStates",
  async (geoId: string, thunkAPI) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(`/rest/s1/fp/geos/${geoId}/regions`, {
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("geo states data", response);
      if (response.status === 200) {
        const mappedArray = response.data.resultList.map((item: any) => {
          return {
            key: item.geoId,
            value: item.geoName,
          };
        });
        return {
          mappedArray,
          states: response?.data?.resultList,
        };
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getShipCarriers: any = createAsyncThunk(
  "auth/getShipCarriers",
  async (_, thunkAPI) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/shipCarriers", {
        params: {
          organizationPartyId: localStorage.getItem("organizationPartyId"),
        },
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("shipCarriers data", response);
      if (response.status === 200) {
        const mappedArray = response.data.shipCarrierList.map((item: any) => {
          return {
            key: item,
            value: item,
          };
        });
        return mappedArray;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getShipMethods: any = createAsyncThunk(
  "auth/getShipMethods",
  async (carrierPartyId: string, thunkAPI) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/shipCarriers/${carrierPartyId}`,
        {
          params: {
            organizationPartyId: localStorage.getItem("organizationPartyId"),
          },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("shipMethods data", response);
      if (response.status === 200) {
        const mappedArray = response.data.shipCarrierMethods.map(
          (item: any) => {
            return {
              key: item.shipmentMethodEnumId,
              value: item.description,
            };
          }
        );
        return mappedArray;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getFacilityInfo: any = createAsyncThunk(
  "auth/getFacilityInfo",
  async (
    { setFacilityInfo, facilityId }: { setFacilityInfo: any; facilityId: any },
    thunkAPI
  ) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(`/rest/s1/fp/facility/${facilityId}`, {
        params: {
          organizationPartyId: localStorage.getItem("organizationPartyId"),
        },
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("ship info data", response);
      if (response.status === 200) {
        setFacilityInfo(response.data);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getProductStoresList: any = createAsyncThunk(
  "auth/getProductStoresList",
  async (_, thunkAPI) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const response = await axios.get(`/rest/s1/fp/productStore`, {
        params: {
          organizationPartyId,
        },
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("getProductStoresList", response);
      if (response.status === 200) {
        const mappedArray = response.data.productStoresList.map((item: any) => {
          return {
            key: item.productStoreId,
            value: item.storeName,
          };
        });
        return mappedArray;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getCustomersList: any = createAsyncThunk(
  "auth/getCustomersList",
  async (
    {
      setMaxPageIndex,
      setPageCount,
      pageIndex,
      searchTerm,
    }: {
      setMaxPageIndex: Function;
      setPageCount: Function;
      pageIndex: any;
      searchTerm?: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let url = `/rest/s1/fp/customer?pageIndex=${pageIndex}`;
      if (searchTerm) {
        url = `/rest/s1/fp/customer?pageIndex=${pageIndex}&term=${searchTerm}`;
      }
      const response = await axios.get(url, {
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
        params: {
          organizationPartyId: localStorage.getItem("organizationPartyId"),
        },
      });
      console.log("Get Customers List", response);
      if (response.status === 200) {
        let mappedArray: any = [];
        mappedArray.push({
          key: "new_customer",
          value: "New Customer",
        });
        response.data.customerList.forEach((item: any) => {
          mappedArray.push({
            key: item.value,
            value: item.label,
          });
        });
        setMaxPageIndex(response.data?.customerListPageMaxIndex);
        return mappedArray;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getShippingAccountList: any = createAsyncThunk(
  "auth/getShippingAccountList",
  async (productStoreId: any, thunkAPI) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/productStore/${productStoreId}/shippingGateways`,
        {
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      console.log("Get Shipping Account List data", response);
      if (response.status === 200) {
        const mappedArray =
          response.data.productStoreShippingGatewayConfigList.map(
            (item: any) => {
              return {
                key: item.carrierPartyId,
                value: item.description,
              };
            }
          );
        return mappedArray;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const downloadCsvTemplate: any = createAsyncThunk(
  "auth/downloadCsvTemplate",
  async (_, thunkAPI) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        "/rest/s1/fp/downloadIncomingShipmentTemplate",
        {
          responseType: "blob",
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("download csv data", response);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "shipmentImportTemplate.csv");
        document.body.appendChild(link);
        link.click();
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getShippingMethods: any = createAsyncThunk(
  "auth/getShippingMethods",
  async (
    {
      setShippingMethods,
      productStoreId,
      carrierPartyId,
    }: { setShippingMethods: any; productStoreId: any; carrierPartyId: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/productStore/${productStoreId}/shipmentMethods`,

        {
          params: {
            carrierPartyId: carrierPartyId,
          },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("shipMethods data", response);
      if (response.status === 200) {
        const mappedArray = response.data.productStoreShipInfoList.map(
          (item: any) => {
            return {
              key: `${item.carrierPartyId}_${item.shipmentMethodEnumId}`,
              value: item.shipmentMethod,
            };
          }
        );
        setShippingMethods(mappedArray);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const checkWholeSalesOrderItem: any = createAsyncThunk(
  "auth/checkWholeSalesOrderItem",
  async (
    {
      data,
      setImportStatus,
      setImportId,
      setUploadCompletionRate,
    }: {
      data: any;
      setImportStatus: any;
      setImportId: any;
      setUploadCompletionRate: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post(
        "/rest/s1/fp/order/validateItems",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            moquiSessionToken: moquiSessionToken,
            "content-type": "multipart/form-data",
          },
        }
      );
      console.log("check whole Sales Order Item", response);
      if (!response) {
        setImportStatus("DataImportHeaderMatchFailed");
        setUploadCompletionRate(100);
      }
      if (response.status === 200) {
        setImportId(response?.data?.importId);
        await thunkAPI.dispatch(
          checkDataImportStatus({
            importId: response?.data?.importId,
            setImportStatus,
            setUploadCompletionRate,
          })
        );
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const checkDataImportStatus = createAsyncThunk(
  "auth/checkDataImportStatus",
  async (
    {
      importId,
      setImportStatus,
      setUploadCompletionRate,
    }: { importId: any; setImportStatus: any; setUploadCompletionRate: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/order/checkImportStatus?importId=${importId}`,
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("check Data import Status", response);
      if (response.status === 200) {
        if (response.data && response.data.importStatus) {
          if (
            response.data.importStatus !== "DataImportSuccess" &&
            response.data.importStatus !== "DataImportCancelled" &&
            response.data.importStatus !== "DataImportFailed"
          ) {
            setTimeout(async () => {
              setUploadCompletionRate((prev: any) => {
                let newValueToReturn = prev * 1;
                if (newValueToReturn < 90) {
                  newValueToReturn += 5;
                }
                return newValueToReturn;
              });
              await thunkAPI.dispatch(
                checkDataImportStatus({
                  importId,
                  setImportStatus,
                  setUploadCompletionRate,
                })
              );
            }, 2000);
          } else {
            if (response.data.importStatus === "DataImportFailed") {
              setImportStatus("DataImportFailed");
              setUploadCompletionRate(100);
            } else if (response.data.importStatus === "DataImportSuccess") {
              setImportStatus("DataImportSuccess");
              setUploadCompletionRate(100);
            }
          }
        }
        console.log("2914", response.data);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const createWholesaleOrder: any = createAsyncThunk(
  "auth/createWholesaleOrder",
  async (
    {
      data,
      history,
      isTestOrder,
    }: { data: any; history: any; isTestOrder: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post("/rest/s1/fp/order", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          moquiSessionToken: moquiSessionToken,
          "content-type": "multipart/form-data",
        },
      });
      console.log("create whole Sales Order Item", response);
      if (response.status === 200) {
        if (response.data && response.data.orderId) {
          if (isTestOrder) {
            history.push("/");
          } else {
            history.push(`/order/${response.data.orderId}`);
          }
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const importData: any = createAsyncThunk(
  "auth/importData",
  async (
    {
      data,
      history,
      notification,
    }: { data: any; history: any; notification?: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.post("/rest/s1/fp/bulkUpload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          moquiSessionToken: moquiSessionToken,
          "content-type": "multipart/form-data",
        },
      });
      console.log("Import bulk data", response);
      if (response.status === 200) {
        console.log("Response => ", response);
        notification["success"]({
          message: "Success",
          description: response?.data?.messages,
        });
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// get customer shipping address rest API
export const getCustomerShippingAddress: any = createAsyncThunk(
  "auth/getCustomerShippingAddress",
  async (
    {
      setCustomerShippingInfo,
      customerPartyId,
    }: { setCustomerShippingInfo: Function; customerPartyId: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/customer/${customerPartyId}/shippingAddress`,
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("get customer shipping address ======>>", response);
      if (response.status === 200) {
        let info: any = {};
        response.data &&
          response.data.custShippingAddressList &&
          response.data.custShippingAddressList.length > 0 &&
          response.data.custShippingAddressList.forEach((item: any) => {
            let contactNum = "";
            if (item.toName) {
              info["name"] = item.toName;
            }
            if (item.partyId) {
              info["partyId"] = item.partyId;
            }
            if (item.address1) {
              info["address1"] = item.address1;
            }
            if (item.address2) {
              info["address2"] = item.address2;
            }
            if (item.city) {
              info["city"] = item.city;
            }
            if (item.postalCode) {
              info["postalCode"] = item.postalCode;
            }
            if (item.countryGeoId) {
              info["country"] = item.countryGeoId;
            }
            if (item.stateProvinceGeoId) {
              info["state"] = item.stateProvinceGeoId;
            }
            if (item.contactMechId) {
              info["postalContactMechId"] = item.contactMechId;
            }
            if (item.emailAddress) {
              info["email"] = item.emailAddress;
            }
            if (item.emailContactMechId) {
              info["emailContactMechId"] = item.emailContactMechId;
            }
             if (item.attnName) {
              info["attnName"] = item.attnName;
            }
            if (item.countryCode) {
              contactNum += `${item.countryCode} `;
            }
            if (item.areaCode) {
              contactNum += `${item.areaCode}-`;
            }
            if (item.contactNumber) {
              contactNum += `${item.contactNumber}`;
            }
            info["contact"] = contactNum;
            if (item.telecomContactMechId) {
              info["telecomContactMechId"] = item.telecomContactMechId;
            }
          });
        setCustomerShippingInfo(info);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//get product category REST API
export const getProductCategoryList: any = createAsyncThunk(
  "auth/getProductCategoryList",
  async (
    {
      setCategoryListData,
      productStoreId,
    }: { setCategoryListData: Function; productStoreId?: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let uri = "/rest/s1/fp/category";
      if (productStoreId) {
        uri = `${uri}?productStoreId=${productStoreId}`;
      }
      const response = await axios.get(uri, {
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      console.log("get Category List", response);
      if (response.status === 200) {
        setCategoryListData(response.data);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const createUser: any = createAsyncThunk(
  "auth/createUser",
  async (
    {
      emailAddress,
      setIsScreenUpdated,
    }: { emailAddress: any; setIsScreenUpdated: Function },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.post(
        `/rest/s1/fp/organization/${organizationPartyId}/sendInvite`,
        {
          emailAddress,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("create user", response);

      if (response.status === 200) {
        if (response.data.securityCode) {
          notification["success"]({
            message: "Success",
            description: "Invitation email sent successfully.",
          });
          setIsScreenUpdated((prev: any) => !prev);
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const validateUser: any = createAsyncThunk(
  "auth/validateUser",
  async (
    {
      email,
      userFullName,
      userName,
      code,
      password,
      passwordVerify,
      history,
    }: {
      email: any;
      userFullName: any;
      userName: any;
      code: any;
      password: any;
      passwordVerify: any;
      history: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.post(
        `/rest/s1/fp/organization/${organizationPartyId}/createUser`,
        {
          emailAddress: email,
          securityCode: code,
          userFullName,
          username: userName,
          newPassword: password,
          newPasswordVerify: passwordVerify,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("create user", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "User created successfully.",
        });
        history.push("/login");
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getActiveOrg: any = createAsyncThunk(
  "auth/getActiveOrg",
  async (_, thunkAPI) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/user/activeOrganization", {
        headers: {
          moquiSessionToken: token,
        },
      });

      console.log("get active orgnization data", response);
      if (response.status === 200) {
        if (
          response.data.organization.partyId !==
          localStorage.getItem("activeOrgId")
        ) {
          localStorage.removeItem("activeStore");
          thunkAPI.dispatch(setActiveStore({}));
        }
        localStorage.setItem("activeOrgId", response.data.organization.partyId);
        localStorage.setItem(
          "organizationPartyId",
          response.data.organization.partyId
        );
        thunkAPI.dispatch(getStoreList());
        thunkAPI.dispatch(getActiveStore());
        return response.data
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getListOfUser: any = createAsyncThunk(
  "auth/getListOfUser",
  async (setListOfUser: Function, thunkAPI) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.get(
        `/rest/s1/fp/organization/${organizationPartyId}/users`,
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("getListOfUser", response);

      if (response.status === 200) {
        setListOfUser(response?.data?.userList);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deactivateUserAccount: any = createAsyncThunk(
  "auth/deactivateUserAccount",
  async (
    {
      userId,
      setIsScreenUpdated,
    }: { userId: any; setIsScreenUpdated: Function },
    thunkAPI
  ) => {
    try {
      const response = await axios.put(
        `/rest/s1/fp/user/${userId}/deactivate`,
        {
          headers: {
            moquiSessionToken: localStorage.getItem("moquiSessionToken"),
          },
        }
      );
      console.log("deactivate User Account", response);

      if (response.status === 200) {
        if (
          response.data &&
          response.data.messages &&
          response.data.messages.includes("successfully disabled")
        ) {
          notification["success"]({
            message: "Success",
            description: "User disabled successfully.",
          });
          setIsScreenUpdated((prev: any) => !prev);
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//integrated the import listing REST API
export const getImportListing: any = createAsyncThunk(
  "auth/getImportListing ",
  async (
    {
      setgetImportListingDetail,
      setMaxPageIndex,
      orderByFields,
      setRecordCount,
      pageIndex,
      pageSize=10,
      queryString,
      appliedFilters,
      uploadedOn_from,
      uploadedOn_thru,
      shipmentId,
    }: {
      setgetImportListingDetail: Function;
      setMaxPageIndex: Function;
      orderByFields: any;
      setRecordCount: Function;
      pageIndex: any;
      pageSize?:any
      queryString?: any;
      appliedFilters: any;
      uploadedOn_from: any;
      uploadedOn_thru: any;
      shipmentId?: any;
    },
    thunkAPI
  ) => {
    try {
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const token: any = localStorage.getItem("moquiSessionToken");
      let paramsToPass: any = {
        orderByFields,
        uploadedOn_from,
        uploadedOn_thru,
      };
      let url = `/rest/s1/fp/bulkUpload?ownerPartyId=${organizationPartyId}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
      if (appliedFilters) {
        url = `/rest/s1/fp/bulkUpload?ownerPartyId=${organizationPartyId}&pageIndex=${pageIndex}&pageSize=${pageSize}&${appliedFilters}`;
      }
      if (shipmentId) {
        url = `${url}&shipmentId=${shipmentId}`;
      }
      const response = await axios.get(url, {
        params: paramsToPass,
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("import listing", response);
      if (response.status === 200) {
        setRecordCount(response.data.dataImportListCount);
        setMaxPageIndex(response.data.dataImportListPageMaxIndex + 1);
        setgetImportListingDetail(response?.data?.dataImportList);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// integrated the template listing REST API
export const getTemplatesList: any = createAsyncThunk(
  "auth/getTemplatesList",
  async ({ setTemplateList }: { setTemplateList: Function }, thunkAPI) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get("/rest/s1/fp/bulkUpload/template", {
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("get template data", response);
      if (response.status === 200) {
        setTemplateList(response.data.dataImportTemplateList);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getStoreList: any = createAsyncThunk(
  "auth/getStoreList",
  async (_, thunkAPI) => {
    try {
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      let token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/productStore?organizationPartyId=${organizationPartyId}`,
        {
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      console.log("get stores data", response);
      if (response.status === 200) {
        return response?.data?.productStoresList;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getActiveStore: any = createAsyncThunk(
  "auth/getActiveStore",
  async (_, thunkAPI) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const response = await axios.get(`/rest/s1/fp/user/activeStore`, {
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("get active store data", response);
      if (response.status === 200) {
        return response?.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const setActiveStore: any = createAsyncThunk(
  "auth/setActiveStore",
  async (productStoreId, thunkAPI) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      let userId: any = localStorage.getItem("userId");
      const response = await axios.put(
        `/rest/s1/fp/user/activeStore`,
        { productStoreId, userId },
        {
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      if (response.status === 200) {
        thunkAPI.dispatch(getActiveStore());
        return response?.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const checkIntegrationAvailable: any = createAsyncThunk(
  "auth/checkIntegrationAvailable",
  async (
    {
      productStoreId,
      setIsIntegrationAvailable,
      showErrorAlert = true,
    }: {
      productStoreId: string;
      setIsIntegrationAvailable: Function;
      showErrorAlert?: boolean;
    },
    thunkAPI
  ) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/productStore/${productStoreId}/isIntegrationAvailable`,
        {
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      if (response.status === 200) {
        // console.log("Response checkIntegrationAvailable => ", response.data);
        setIsIntegrationAvailable(response?.data?.isIntegrationAvailable);
        if (response?.data?.isIntegrationAvailable && showErrorAlert) {
          thunkAPI.dispatch(setActiveStore(productStoreId));
        } else if (showErrorAlert) {
          notification["error"]({
            message: "Error",
            description: "Integration not available for selected store.",
          });
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateStoreConfig: any = createAsyncThunk(
  "auth/updateStoreConfig",
  async (
    {
      importEnable,
      productStoreId,
    }: { importEnable: string; productStoreId: Function },
    thunkAPI
  ) => {
    try {
      // const organizationPartyId = localStorage.getItem("organizationPartyId");
      let token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        `/rest/s1/fp/productStore/${productStoreId}`,
        { importEnable },
        {
          // params: {
          //   importEnable,
          //   productStoreId,
          // },
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      console.log("update stores data", response);
      if (response.status === 200) {
        thunkAPI.dispatch(getStoreList());
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// integrated the csv Template Required Fields REST API
export const getOrderInstructions: any = createAsyncThunk(
  "auth/getOrderInstructions",
  async (
    {
      productStoreId,
      setInstructions,
    }: { productStoreId: any; setInstructions: Function },
    thunkAPI
  ) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const response = await axios.get(
        `/rest/s1/fp/order/csvTemplateRequiredFields?productStoreId=${productStoreId}`,
        {
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      console.log("get active store data", response);
      if (response.status === 200) {
        setInstructions(response?.data?.requiredFieldList);
        return response?.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Add/Update product image REST API
export const uploadProductImage: any = createAsyncThunk(
  "auth/uploadProductImage",
  async (
    {
      data,
      productId,
      fetchData,
    }: { data: any; productId: any; fetchData: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.post(
        `/rest/s1/fp/product/${productId}/image`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            moquiSessionToken: moquiSessionToken,
            "content-type": "multipart/form-data",
          },
        }
      );
      console.log("Produt Image data", response);
      if (response.status === 200) {
        console.log("Response => ", response);
        if (notification) {
          notification["success"]({
            message: "Success",
            description: "Product Image uploaded successfully!",
          });
        }
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//integrated the product listing REST API
export const getProductList: any = createAsyncThunk(
  "auth/getProductList",
  async (
    {
      productList,
      setProductList,
      setMaxPageIndex,
      orderByFields,
      setRecordCount,
      pageIndex,
      queryString,
      appliedFilters,
    }: {
      productList: any;
      setProductList: Function;
      setMaxPageIndex: Function;
      orderByFields: any;
      setRecordCount: Function;
      pageIndex: any;
      queryString?: any;
      appliedFilters: any;
    },
    thunkAPI
  ) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      let url = `/rest/s1/fp/product?pageIndex=${pageIndex}&pageSize=10`;
      if (appliedFilters) {
        url = `/rest/s1/fp/product?pageIndex=${pageIndex}&pageSize=10&${appliedFilters}`;
      }
      const response = await axios.get(url, {
        params: {
          orderByFields,
        },
        headers: {
          moquiSessionToken: token,
        },
      });
      console.log("product listing", response);
      if (response.status === 200) {
        setRecordCount(response.data.productListCount);
        setMaxPageIndex(response.data.productListPageMaxIndex + 1);
        var filtedProductList: any = [];
        response?.data?.productList?.map((item: any) => {
          let sku = "";
          let upca = "";
          item.otherIds.forEach((item: any) => {
            if (item.productIdTypeEnumId === "PidtUpca") {
              upca = item.idValue ? item.idValue : "";
            } else if (item.productIdTypeEnumId === "PidtSku") {
              sku = item.idValue ? item.idValue : "";
            }
          });
          filtedProductList.push({
            productId: item.productId,
            pseudoId: item.pseudoId,
            productName: item.productName,
            sku: sku,
            upca: upca,
            images: item?.images,
          });
          return null;
        });
        setProductList([...productList, ...filtedProductList]);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Integrated product Searching REST API
export const searchProductListing: any = createAsyncThunk(
  "auth/searchProductListing",
  async (
    {
      setSearchProdsInFocus,
      productList,
      setProductList,
      currPage,
      queryString,
      setHasMoreProds,
      setMaxPageIndex,
    }: {
      setSearchProdsInFocus?: Function;
      productList: any[];
      setProductList: Function;
      currPage: any;
      queryString: string;
      setHasMoreProds: Function;
      setMaxPageIndex: Function;
    },
    thunkAPI
  ) => {
    try {
      const token: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const response = await axios.get(
        `/rest/s1/fp/search?pageIndex=${currPage}`,
        {
          params: {
            queryString,
            ownerPartyId: organizationPartyId,
          },
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      console.log("search product listing API", response);
      if (response.status === 200) {
        if (currPage > 0) {
          setProductList([...productList, ...response.data.documentList]);
        } else {
          setProductList(response.data.documentList);
        }
        setMaxPageIndex(response.data.productListPageMaxIndex + 1);
        // setSearchProdsInFocus(true);
        if (10 * currPage >= response.data.documentListCount) {
          setHasMoreProds(false);
        }
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Add Shipment Item
export const addShipmentItem: any = createAsyncThunk(
  "auth/addShipmentItem",
  async (
    {
      data,
      shipmentId,
      setIsAddItemModalOpen,
      fetchData,
      formAdd,
      setSelectedProduct,
    }: {
      data: any;
      shipmentId: any;
      setIsAddItemModalOpen: Function;
      fetchData: any;
      formAdd?: any;
      setSelectedProduct: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.post(
        `/rest/s1/fp/shipment/${shipmentId}/item`,
        {
          ...data,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Add Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Shipment item added successfully!.",
        });
        setIsAddItemModalOpen(false);
        formAdd?.resetFields();
        fetchData();
        setSelectedProduct(null);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Update Shipment Item
export const updateShipmentItem: any = createAsyncThunk(
  "auth/updateShipmentItem",
  async (
    {
      data,
      shipmentId,
      setIsUpdateModalOpen,
      fetchData,
      formUpdate,
    }: {
      data: any;
      shipmentId: any;
      setIsUpdateModalOpen: Function;
      fetchData: any;
      formUpdate?: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.put(
        `/rest/s1/fp/shipment/${shipmentId}/item`,
        {
          ...data,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Update Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Shipment item updated successfully!.",
        });
        setIsUpdateModalOpen(false);

        formUpdate?.resetFields();
        fetchData();

        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Add order Item
export const addOrderItem: any = createAsyncThunk(
  "auth/addOrderItem",
  async (
    {
      data,
      orderId,
      setIsAddItemModalOpen,
      fetchData,
      formAdd,
      setSelectedProduct,
    }: {
      data: any;
      orderId: any;
      setIsAddItemModalOpen: Function;
      fetchData: any;
      formAdd?: any;
      setSelectedProduct: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.post(
        `/rest/s1/fp/order/${orderId}/item`,
        {
          ...data,
          organizationPartyId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Add Order Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Order item added successfully!.",
        });
        setIsAddItemModalOpen(false);
        formAdd?.resetFields();
        fetchData();
        setSelectedProduct(null);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Update Order Item
export const updateOrderItem: any = createAsyncThunk(
  "auth/updateShipmentItem",
  async (
    {
      data,
      orderId,
      setIsUpdateModalOpen,
      fetchData,
      updateForm,
    }: {
      data: any;
      orderId: any;
      setIsUpdateModalOpen: Function;
      fetchData: any;
      updateForm?: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.put(
        `/rest/s1/fp/order/${orderId}/item/${data?.orderItemSeqId}`,
        {
          ...data,
          organizationPartyId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Update Order Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Order item updated successfully!.",
        });
        setIsUpdateModalOpen(false);

        updateForm?.resetFields();
        fetchData();

        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Create product
export const createProduct: any = createAsyncThunk(
  "auth/createProduct",
  async ({ data, history }: { data: any; history: any }, thunkAPI) => {
    try {
      const ownerPartyId = localStorage.getItem("organizationPartyId");
      const userId=localStorage.getItem("userId")
      let token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post(
        `/rest/s1/fp/product`,
        { ...data, ownerPartyId,userId },
        {
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      console.log("create product", response);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product created successfully!",
        });
        history.push(`/product/${response?.data?.productId}`);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Update product
export const updateProduct: any = createAsyncThunk(
  "auth/createProduct",
  async ({ productId, productTypeEnumId,serialized }: { productId: any; productTypeEnumId: any, serialized:any}, thunkAPI) => {
    try {
      const ownerPartyId = localStorage.getItem("organizationPartyId");
      let token: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        `/rest/s1/fp/product`,
        { productId,productTypeEnumId,ownerPartyId,serialized },
        {
          headers: {
            moquiSessionToken: token,
          },
        }
      );
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product updated successfully!",
        });
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Add Assoc Product
export const addAssocProduct: any = createAsyncThunk(
  "auth/addAssocProduct",
  async (
    {
      data,
      productId,
      setIsAddItemModalOpen,
      fetchData,
      formAdd,
      setSelectedProduct,
      setProductList,
    }: {
      data: any;
      productId: any;
      setIsAddItemModalOpen: Function;
      fetchData: any;
      formAdd?: any;
      setSelectedProduct: any;
      setProductList: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.post(
        `/rest/s1/fp/product/${productId}/associate`,
        {
          ...data,
          organizationPartyId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Add Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product added successfully!",
        });
        setIsAddItemModalOpen(false);
        formAdd?.resetFields();
        fetchData();
        setSelectedProduct(null);
        setProductList([]);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// add product identification
export const addProductIdentification: any = createAsyncThunk(
  "auth/addAssocProduct",
  async (
    {
      productIdTypeEnumId,
      idValue,
      productId,
      setIsAddProductModalOpen,
      fetchData,
      formAddProduct,
    }: {
      productIdTypeEnumId: any;
      idValue: any;
      productId: any;
      setIsAddProductModalOpen: Function;
      fetchData: any;
      formAddProduct?: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const userId:any=localStorage.getItem("userId")
      const response = await axios.post(
        `/rest/s1/fp/product/${productId}/productIdentification`,
        {
          productId,
          productIdTypeEnumId,
          idValue,
          userId
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Add Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product Identification added successfully!",
        });
        setIsAddProductModalOpen(false);
        formAddProduct?.resetFields();
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// update product name and description
export const updateProductNameAndDescription: any = createAsyncThunk(
  "auth/updateProductNameAndDescription",
  async (
    {
      productId,
      productName,
      description,
    }: {
      productId: any;
      productName: any;
      description: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        `/rest/s1/fp/product/${productId}`,
        {
          productName,
          description,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: productName
            ? "Product Name Updated Successfully!"
            : "Product Description Updated Successfully",
        });
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// update product price
export const updateProductPrice: any = createAsyncThunk(
  "auth/updateProductPrice",
  async (
    {
      productId,
      priceTypeEnumId,
      productPriceId,
      price,
    }: {
      productId: any;
      priceTypeEnumId: any;
      productPriceId: any;
      price: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        `rest/s1/fp/product/${productId}/productPrice`,
        {
          productId,
          productPriceId,
          priceTypeEnumId,
          price,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Add Item", response);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product Price Updated Successfully!",
        });
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// add product price
export const addProductPrice: any = createAsyncThunk(
  "auth/addProductPrice",
  async (
    {
      productId,
      priceTypeEnumId,
      pricePurposeEnumId,
      price,
    }: {
      productId: any;
      priceTypeEnumId: any;
      pricePurposeEnumId: any;
      price: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post(
        `rest/s1/fp/product/${productId}/productPrice`,
        {
          productId,
          priceTypeEnumId,
          pricePurposeEnumId,
          price,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Add Item", response);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product Price Added Successfully!",
        });
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Update Assoc Product
export const updateAssocProduct: any = createAsyncThunk(
  "auth/addAssocProduct",
  async (
    {
      data,
      productId,
      setIsUpdateItemModalOpen,
      fetchData,
      formUpdate,
    }: {
      data: any;
      productId: any;
      setIsUpdateItemModalOpen: Function;
      fetchData: any;
      formUpdate?: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.put(
        `/rest/s1/fp/product/${productId}/associate`,
        {
          ...data,
          organizationPartyId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Update Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product updated successfully!",
        });
        setIsUpdateItemModalOpen(false);
        formUpdate?.resetFields();
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Update product Identification
export const updateProductIdentification: any = createAsyncThunk(
  "auth/addAssocProduct",
  async (
    {
      productIdTypeEnumId,
      idValue,
      productId,
      setIsUpdateProductModalOpen,
      fetchData,
      formUpdateProduct,
    }: {
      productIdTypeEnumId: any;
      idValue: any;
      productId: any;
      setIsUpdateProductModalOpen: Function;
      fetchData: any;
      formUpdateProduct?: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const userId:any=localStorage.getItem("userId");
      const response = await axios.put(
        `/rest/s1/fp/product/${productId}/productIdentification`,
        {
          idValue,
          productId,
          productIdTypeEnumId,
          userId
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Update Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product identification updated successfully!",
        });
        setIsUpdateProductModalOpen(false);
        formUpdateProduct?.resetFields();
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Update Assoc Product
export const deleteAssocProduct: any = createAsyncThunk(
  "auth/deleteAssocProduct",
  async (
    {
      data,
      productId,
      fetchData,
    }: {
      data: any;
      productId: any;
      fetchData: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.delete(
        `/rest/s1/fp/product/${productId}/associate`,
        {
          params: {
            ...data,
            organizationPartyId,
          },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Delete Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product deleted successfully!",
        });
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// delet Product Identification
export const deleteProductIdentification: any = createAsyncThunk(
  "auth/deleteAssocProduct",
  async (
    {
      productId,
      productIdTypeEnumId,
      fetchData,
    }: {
      data: any;
      productId: any;
      productIdTypeEnumId: any;
      fetchData: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.delete(
        `/rest/s1/fp/product/${productId}/productIdentification`,
        {
          params: {
            productId,
            productIdTypeEnumId,
          },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Delete Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Product Identification deleted successfully!",
        });
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteInventoryItem: any = createAsyncThunk(
  "auth/deleteInventoryItem",
  async (
    {
      shipmentId,
      productId,
      quantityReceived,
      fetchData,
    }: {
      shipmentId: any;
      productId: any;
      quantityReceived: any;
      fetchData: Function;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.delete(
        `/rest/s1/fp/shipment/${shipmentId}/item/${productId}`,
        {
          params: {
            shipmentId,
            productId,
            quantityReceived,
          },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Delete Item", response);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Inventory Item deleted successfully!",
        });
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAssetOnHandSummaryReport: any = createAsyncThunk(
  "auth/getAssetOnHandSummaryReport",
  async (
    {
      orderByFields,
      setAssetsReportListing,
      setRecordCount,
      setMaxPageIndex,
      pageIndex,
      facilityId,
      selectedFromDate,
      selectedThruDate,
      productSearch,
      setAllAssetsReport,
    }: {
      orderByFields: any;
      setAssetsReportListing: any;
      setRecordCount: Function;
      setMaxPageIndex: Function;
      pageIndex: any;
      facilityId: any;
      selectedFromDate: any;
      selectedThruDate: any;
      productSearch: any;
      setAllAssetsReport: any;
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const paramsToPass: any = {
        orderByFields,
        facilityId,
        fromDate: selectedFromDate,
        thruDate: selectedThruDate,
        productId: productSearch,
        organizationPartyId,
      };
      const url = `/rest/s1/fp/reports/assetOnHandSummaryReport?pageIndex=${pageIndex}&pageSize=10`;
      const response = await axios.get(url, {
        params: paramsToPass,
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      const responseWithoutPaginate = await axios.get(
        `${url}&isPaginate=false`,
        {
          params: paramsToPass,
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (responseWithoutPaginate.status === 200) {
        setAllAssetsReport(responseWithoutPaginate.data.summaryList);
      } else {
        return thunkAPI.rejectWithValue(response);
      }
      console.log("Get AssetOnHandReport data", response);
      if (response.status === 200) {
        setAssetsReportListing(response.data.summaryList);
        if (
          response.data.summaryListCount >
          10 * response.data.summaryListPageMaxIndex
        ) {
          setMaxPageIndex(response.data.summaryListPageMaxIndex + 1);
        } else {
          setMaxPageIndex(response.data.summaryListPageMaxIndex);
        }
        setRecordCount(response.data.summaryListCount);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getDashboardDetails: any = createAsyncThunk(
  "auth/getDashboardDetails",
  async ({ setDashboardData }: { setDashboardData: any }, thunkAPI) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let organizationPartyId: any = localStorage.getItem(
        "organizationPartyId"
      );
      const response = await axios.get(
        `/rest/s1/fp/organization/getDashboardDetail?organizationPartyId=${organizationPartyId}`,
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        console.log("get Dashboard Data", response);
        setDashboardData(response.data);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Add order Attachments
export const addOrderAttachment: any = createAsyncThunk(
  "auth/addOrderAttachment",
  async (
    {
      formData,
      fetchData,
      setIsDocumentModalOpen,
    }: { formData: any; fetchData: any; setIsDocumentModalOpen: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.post(
        "/rest/s1/fp/order/orderAttachment",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            moquiSessionToken: moquiSessionToken,
            "content-type": "multipart/form-data",
          },
        }
      );
      console.log("Order attachment data", response);
      if (response.status === 200) {
        console.log("Response => ", response);
        fetchData();
        if (notification) {
          notification["success"]({
            message: "Success",
            description: "Attachment added successfully.",
          });
        }
        setIsDocumentModalOpen(false);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Delete order Attachments
export const deleteOrderAttachment: any = createAsyncThunk(
  "auth/deleteOrderAttachment",
  async (
    { orderContentId, fetchData }: { orderContentId: any; fetchData: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.put(
        `/rest/s1/fp/order/updateOrderAttachment?orderContentId=${orderContentId}`,
        {},
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Order attachment data", response);
      if (response.status === 200) {
        fetchData();
        if (notification) {
          notification["success"]({
            message: "Success",
            description: "Attachment deleted successfully.",
          });
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Add shipment Attachments
export const addShipmentAttachment: any = createAsyncThunk(
  "auth/addShipmentAttachment",
  async (
    {
      shipmentId,
      formData,
      fetchData,
      setIsDocumentModalOpen,
    }: {
      shipmentId: any;
      formData: any;
      fetchData: any;
      setIsDocumentModalOpen: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.post(
        `/rest/s1/fp/incomingShipments/shipmentContent?shipmentId=${shipmentId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            moquiSessionToken: moquiSessionToken,
            "content-type": "multipart/form-data",
          },
        }
      );
      console.log("Shipment attachment data", response);
      if (response.status === 200) {
        console.log("Response => ", response);
        fetchData();
        if (notification) {
          notification["success"]({
            message: "Success",
            description: "Attachment added successfully.",
          });
        }
        setIsDocumentModalOpen(false);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Delete shipment Attachments
export const deleteShipmentAttachment: any = createAsyncThunk(
  "auth/deleteShipmentAttachment",
  async (
    {
      shipmentContentId,
      fetchData,
    }: { shipmentContentId: any; fetchData: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.put(
        `/rest/s1/fp/incomingShipments/updateShipmentContent?shipmentContentId=${shipmentContentId}`,
        {},
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Shipment attachment data", response);
      if (response.status === 200) {
        fetchData();
        if (notification) {
          notification["success"]({
            message: "Success",
            description: "Attachment deleted successfully.",
          });
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const cancelOrder: any = createAsyncThunk(
  "auth/cancelOrder",
  async (
    {
      orderId,
      fetchData,
    }: {
      orderId: any;
      fetchData: any;
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        `/rest/s1/fp/order/${orderId}/cancelOrder`,
        {
          params: { orderId },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Cancel Order data", response);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Order cancelled successfully!",
        });
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Update shipment shipping method
export const updateShipmentShippingMethod: any = createAsyncThunk(
  "auth/updateShipmentShippingMethod",
  async (
    {
      shipmentId,
      carrierPartyId,
      shipmentMethodEnumId,
      setIsShippingMethodModalOpen,
      fetchData,
    }: {
      shipmentId: any;
      carrierPartyId: any;
      shipmentMethodEnumId: any;
      setIsShippingMethodModalOpen: Function;
      fetchData: Function;
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        "/rest/s1/fp/shipment/updateShipmentShippingMethod",
        { shipmentId, carrierPartyId, shipmentMethodEnumId },
        {
          // params: { },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Shipment method updated successfully!",
        });
        setIsShippingMethodModalOpen(false);
        fetchData();
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Get all shipping methods
export const getAllShippingMethods: any = createAsyncThunk(
  "auth/getAllShippingMethods",
  async (
    {
      setShippingMethods,
      productStoreId,
    }: {
      setShippingMethods: Function;
      productStoreId: any;
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/getShippingOptions?productStoreId=${productStoreId}`,
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("Shipping methods data", response?.data);
      if (response.status === 200) {
        setShippingMethods(response?.data?.shippingOptions);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const orderHoldStatus: any = createAsyncThunk(
  "auth/orderHoldStatus",
  async (
    {
      orderId,
      statusId,
      orderPartSeqId,
      fetchData,
    }: { orderId: any; statusId: any; orderPartSeqId: any; fetchData: any },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.put(
        `/rest/s1/fp/order/${orderId}/orderHold`,
        { orderId, statusId, orderPartSeqId },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        console.log("resonse.data", response?.data);
        notification["success"]({
          message: "Success",
          description: "Order held successfully!",
        });
        fetchData();
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e?.response?.data);
    }
  }
);
export const orderApprove: any = createAsyncThunk(
  "auth/orderApprove",
  async (
    { orderId, fetchData }: { orderId: any; fetchData: Function },
    ThunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.put(
        `/rest/s1/fp/order/${orderId}/approveOrder`,
        { orderId },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        notification["success"]({
          message: "success",
          description: "Order approved sucessfully",
        });
        fetchData();
        return response;
      } else {
        return ThunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return ThunkAPI.rejectWithValue(e.response?.data);
    }
  }
);
export const getPartyDetailRoleType: any = createAsyncThunk(
  "auth/getPartyDetailRoleType",
  async (
    {
      organizationPartyId,
      setPartyIdListing,
      setPageCount,
      setMaxPageIndex,
      pageIndex,
      searchTerm,
    }: {
      organizationPartyId: any;
      setPartyIdListing: Function;
      setPageCount: Function;
      setMaxPageIndex: any;
      pageIndex: any;
      searchTerm?: any;
    },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let url = `/rest/s1/fp/organization/${organizationPartyId}?pageIndex=${pageIndex}&term`;
      if (searchTerm) {
        url = `/rest/s1/fp/organization/${organizationPartyId}?pageIndex=${pageIndex}&term=${searchTerm}`;
      }
      let response: any = await axios.get(url, {
        headers: {
          moquiSessionToken: moquiSessionToken,
        },
      });
      if (response.status === 200) {
        console.log("partiesres", response);
        let mappedArray: any = [];
        let organizationNamesSet = new Set();
        mappedArray.push({
          key: "new_customer",
          value: "New Customer",
        });
        response?.data?.partyDetailAndRoleList.forEach((item: any) => {
          if (!organizationNamesSet.has(item.organizationName)) {
            mappedArray.push({
              key: item.partyId,
              value: item.organizationName,
            });
            organizationNamesSet.add(item.organizationName);
          }
        });
        setMaxPageIndex(response?.data?.partyDetailAndRoleListPageMaxIndex);
        setPartyIdListing(mappedArray);
        return response?.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getIncomingShipmentAddress: any = createAsyncThunk(
  "auth/getIncomingShipmentAddress",
  async (
    {
      setCustomerShippingInfo,
      PartyId,
    }: { setCustomerShippingInfo: Function; PartyId: any },
    thunkAPI
  ) => {
    try {
      const moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.get(
        `/rest/s1/fp/customer/${PartyId}/partyDetail`,
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      console.log("get customer shipping address ======>>", response);
      if (response.status === 200) {
        let info: any = {};
        response.data &&
          response.data.partyContactDetail.length > 0 &&
          response.data.partyContactDetail.forEach((item: any) => {
            let contactNum = "";
            if (item.toName) {
              info["name"] = item.toName;
            }
            if (item.address1) {
              info["address1"] = item.address1;
            }
            if (item.address2) {
              info["address2"] = item.address2;
            }
            if (item.city) {
              info["city"] = item.city;
            }
            if (item.postalCode) {
              info["postalCode"] = item.postalCode;
            }
            if (item.countryGeoId) {
              info["country"] = item.countryGeoId;
            }
            if (item.stateProvinceGeoId) {
              info["state"] = item.stateProvinceGeoId;
            }
            if (item.infoString) {
              info["email"] = item.infoString;
            }
            if (item.countryCode) {
              contactNum += `${item.countryCode} `;
            }
            if (item.areaCode) {
              contactNum += `${item.areaCode}-`;
            }
            if (item.contactNumber) {
              contactNum += `${item.contactNumber}`;
            }
            info["contact"] = contactNum;
            // if (item.telecomContactMechId) {
            //   info["telecomContactMechId"] = item.telecomContactMechId;
            // }
          });
        setCustomerShippingInfo(info);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getOrganizationList: any = createAsyncThunk(
  "auth/getOrganizationList",
  async (
    {
      setOrganizationName,
    }: {
      setOrganizationName: Function;
    },
    thunkAPI
  ) => {
    try {
      const moquisessiontoken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.get(
        `/rest/s1/handheld/user/organization`,
        {
          headers: {
            moquiSessionToken: moquisessiontoken,
          },
        }
      );
      if (response.status === 200) {
        console.log("organizationresponse", response);
        let mappedArray: any = [];
        response.data.organizationList.map((item: any) => {
          mappedArray.push({
            key: item.partyId,
            value: item.organizationName,
          });
          // console.log("mappedarray", mappedArray);
          setOrganizationName(mappedArray);
          return response.data.organizationList;
        });
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getLotList: any = createAsyncThunk(
  "auth/getLotList",
  async (
    {
      productId,
      setLotList,
    }: {
      productId: any;
      setLotList: Function;
    },
    thunkAPI
  ) => {
    try {
      const moquisessiontoken: any = localStorage.getItem("moquiSessionToken");

      const response: any = await axios.get(
        `/rest/s1/fp/product/${productId}/lotList`,
        {
          headers: {
            moquiSessionToken: moquisessiontoken,
          },
        }
      );
      if (response.status === 200) {
        let mappedArray: any = [];
        response.data.map((item: any) => {
          mappedArray.push({
            key: item.value,
            value: item.label,
          });
          // console.log("lotlist&&&&", response.data);
          // console.log("lotlabel", mappedArray);
          setLotList(mappedArray);
          return response.data;
        });
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// Get Product Assets List
export const getProductAssetList: any = createAsyncThunk(
  "auth/getContainerList",
  async (
    {
      productId,
      setProductAssetList,
      setListCount,
      setAssetListMaxPageIndex,
      pageIndex,
      pageSize=10
    }: {
      productId: any;
      setProductAssetList: Function;
      setListCount: Function;
      setAssetListMaxPageIndex: Function;
      pageIndex: any;
      pageSize?:any
    },
    thunkAPI
  ) => {
    try {
      const moquisessiontoken: any = localStorage.getItem("moquiSessionToken");
      const response: any = await axios.get(
        `/rest/s1/fp/product/${productId}/productFindAsset?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        {
          headers: {
            moquiSessionToken: moquisessiontoken,
          },
        }
      );
      if (response.status === 200) {
        console.log("containerresponse", response.data.productAssetList);
        setProductAssetList(response.data.productAssetList);
        if (
          response?.data?.productAssetListCount >
          10 * response?.data?.productAssetListPageMaxIndex
        ) {
          setAssetListMaxPageIndex(
            response?.data?.productAssetListPageMaxIndex + 1
          );
        } else {
          setAssetListMaxPageIndex(
            response?.data?.productAssetListPageMaxIndex
          );
        }
        setListCount(response?.data?.productAssetListCount);
        return response.data.productAssetList;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const adjustingInventory: any = createAsyncThunk(
  "auth/adjustingInventory",
  async (
    {
      productId,
      facilityId,
      quantity,
      locationSeqId,
      lotId,
      statusId,
      ownerPartyId,
    }: {
      productId: any;
      facilityId: any;
      quantity: any;
      locationSeqId: any;
      lotId: any;
      statusId: any;
      ownerPartyId: any;
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.put(
        `/rest/s1/fp/product/${productId}/inventoryAdjustment`,
        {
          productId,
          facilityId,
          quantity,
          locationSeqId,
          lotId,
          statusId,
          ownerPartyId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Successfully Updated",
        });
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const receiveDirect: any = createAsyncThunk(
  "auth/receiveDirect",
  async (
    {
      productId,
      facilityId,
      quantity,
      ownerPartyId,
      lotId,
      statusId,
      locationSeqId,
    }: {
      productId: any;
      facilityId: any;
      quantity: any;
      ownerPartyId: any;
      lotId: any;
      statusId: any;
      locationSeqId: any;
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      const response = await axios.post(
        `/rest/s1/fp/product/${productId}/directAssetReceiving`,
        {
          facilityId,
          quantity,
          ownerPartyId,
          lotId,
          locationSeqId,
          statusId,
        },
        {
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Assets Received successfully",
        });
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getReviewingInventoryHistory: any = createAsyncThunk(
  "auth/getReviewingInventoryHistory",
  async (
    {
      productId,
      facilityId,
      setAssetHistory,
      setAssetHistoryListMaxPageIndex,
      pageIndex,
      pageSize=10,
      setRecordCount,
      isPaginate,
      allFacilities,
      setIsAllFacilities,
      appliedFilters,
      orderByFields
    }: {
      productId: any;
      facilityId: any;
      setAssetHistory: Function;
      setAssetHistoryListMaxPageIndex: Function;
      pageIndex: any;
      pageSize?:any
      setRecordCount: Function;
      isPaginate?:Boolean
      allFacilities?:Boolean
      setIsAllFacilities?:any
      appliedFilters:any
      orderByFields:any
    },
    thunkAPI
  ) => {
    try {
      let moquiSessionToken: any = localStorage.getItem("moquiSessionToken");
      let url:any=`/rest/s1/fp/product/${productId}/inventoryHistory?facilityId=${allFacilities?"":facilityId}&pageIndex=${pageIndex}&pageSize=${pageSize}`
      if(appliedFilters){
        url=`${url}&${appliedFilters}`
      }
      if(!isPaginate){
        url=`/rest/s1/fp/product/${productId}/inventoryHistory?facilityId=${allFacilities?"":facilityId}&isPaginate=false`
      }
      if(!isPaginate&&appliedFilters){
        url=`/rest/s1/fp/product/${productId}/inventoryHistory?facilityId=${allFacilities?"":facilityId}&isPaginate=false&${appliedFilters}`
      }
      const response = await axios.get(
        `${url}`,
        {
          params:{
            orderByFields 
          },
          headers: {
            moquiSessionToken: moquiSessionToken,
          },
        }
      );
      if (response.status === 200) {
         console.log("appliedFilters",appliedFilters)
         if(allFacilities){
           setIsAllFacilities(true)
         }
        setAssetHistory(response?.data?.assetHistoryList);
        if (
          response?.data?.assetHistoryCount >
          10 * response?.data?.assetHistoryPageMaxIndex
        ) {
          setAssetHistoryListMaxPageIndex(
            response?.data?.assetHistoryPageMaxIndex + 1
          );
        } else {
          setAssetHistoryListMaxPageIndex(
            response?.data?.assetHistoryPageMaxIndex
          );
        }
        setRecordCount(response?.data?.assetHistoryCount);
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllFacilityList: any = createAsyncThunk(
  "auth/getAllFacilityList",
  async (
    {
      setFacilityList,
      setSelectedFacility,
      fromInventory,
    }: {
      setFacilityList: Function;
      setSelectedFacility?: any;
      fromInventory: any;
    },
    thunkAPI
  ) => {
    try {
      let token: any = localStorage.getItem("moquiSessionToken");
      const organizationPartyId = localStorage.getItem("organizationPartyId");
      const response = await axios.get(`/rest/s1/fp/facility/facilitiesList`, {
        headers: {
          moquiSessionToken: token,
        },
        params: {
          ownerPartyId: organizationPartyId,
        },
      });

      console.log("get Facility List", response.data.facilityList);
      if (response.status === 200) {
        let mappedArray: any = [];
        response.data.facilityList.forEach((item: any) => {
          mappedArray.push({
            key: item.facilityId,
            value: item.facilityName,
          });
        });
        if (fromInventory) {
          mappedArray = mappedArray.filter(
            (val: any, index: any, self: any) =>
              index ===
              self.findIndex(
                (t: any) => t.key === val.key && t.value === val.value
              )
          );
          if (mappedArray.length > 0) {
            setSelectedFacility(mappedArray[0]["key"]);
          } else {
            setSelectedFacility("None");
          }
          setFacilityList(mappedArray);
        } else {
          setFacilityList(mappedArray);
        }
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// getErrorReport
export const getErrorReport:any=createAsyncThunk(
  'auth/getErrorReport',
  async(
    {
     setErrorReportList,
     pageIndex,
     pageSize=10,
     setRecordCount,
     setErrorListPageMaxIndex,
     appliedFilters,
     queryString,
     placedDate_from,
     placedDate_thru
    }:{
    setErrorReportList:Function
    pageIndex:any,
    pageSize?:any
    setRecordCount:Function,
    setErrorListPageMaxIndex:Function,
    appliedFilters?:any
    queryString:any
    placedDate_from?:any
    placedDate_thru?:any
    },
  thunkAPI
  )=>{
    try{
      let token: any = localStorage.getItem("moquiSessionToken");
     const ownerPartyId=localStorage.getItem("activeOrgId")
     const paramsToPass: any = {
      ...(placedDate_from && { startDate: placedDate_from }),
      ...(placedDate_thru && { endDate: placedDate_thru })
    };
     if(queryString){
      if (appliedFilters) {
        const filtersArr = appliedFilters.split("&");
        const index = filtersArr.findIndex((item: any) =>
          item.includes("queryString")
        );
        if (index > -1) {
          let extractedQuery = filtersArr[index];
          extractedQuery += ` AND ${queryString}`;
          filtersArr[index] = extractedQuery;
          const joinedFilters = filtersArr.join("&");
          appliedFilters = joinedFilters;
        } else {
          appliedFilters += `&queryString=${queryString}`;
        }
      } else {
        appliedFilters += `queryString=${queryString}`;
      }
     }
     let url=`/rest/s1/fp/order/errorReport?organization=${ownerPartyId}&pageIndex=${pageIndex}&pageSize=${pageSize}`
     if(appliedFilters){
      url=`${url}&${appliedFilters}`
     }
      const Response= await axios.get(url,{
        params: paramsToPass,
        headers:{
            moquiSessionToken:token
          }
        })
      if(Response.status==200){
         setErrorReportList(Response?.data?.ErrorReport)
         if (
          Response?.data?.errorListCount >
          10 * Response?.data?.errorListPageMaxIndex
        ) {
          setErrorListPageMaxIndex(
            Response?.data?.errorListPageMaxIndex + 1
          );
        } else {
          setErrorListPageMaxIndex(
            Response?.data?.errorListPageMaxIndex
          );
        }
        setRecordCount(Response?.data?.errorListCount);
      }
      return Response
    }catch(error:any){
       return thunkAPI.rejectWithValue(error.response.data);
    }

})

// resetErrorMsg
export const resetErrorMsg:any=createAsyncThunk(
  'auth/resetErrorMsg',
  async(
    {
      systemMessageId,
      fetchData
    }:{
      systemMessageId:any
      fetchData:any
    },
  thunkAPI
  )=>{
    try{
      let token: any = localStorage.getItem("moquiSessionToken");
      const Response= await axios.post(`/rest/s1/fp/order/resetErrorMessage`,
        {
          systemMessageId
        },
        {
        headers:{
            moquiSessionToken:token
          }
        })
        if(Response.status==200){
            fetchData()
        }
      return Response
    }catch(error:any){
       return thunkAPI.rejectWithValue(error.response.data);
    }
})


// channelIntegration
export const channelIntegration:any=createAsyncThunk(
  'auth/channelIntegration',
  async(
    {
       productStoreId,
       displayOrderId,
       externalOrderId,
       integrationId
    }:{
      productStoreId:any
      displayOrderId:any,
       externalOrderId:any
       integrationId:any
    },
  thunkAPI
  )=>{
    try{
      let token: any = localStorage.getItem("moquiSessionToken");
      const response= await axios.get(`/rest/s1/fp/productStore/${productStoreId}/channelIntegrations`,
      {
        params:{
          integrationId
        },
        headers:{
            moquiSessionToken:token
          }
        })
        if(response.status==200){
             if(response.data.messages){
                notification["success"]({
                  message:"success",
                  description:response?.data?.messages
                })
            }
            await thunkAPI.dispatch(
              importErrorMsg({
                externalOrderId,
                displayOrderId,
                integrationId,
                integrationTypeEnumId:response?.data?.integrationList[0]?.integrationTypeEnumId
              })
            );
          }
      return response
    }catch(error:any){
       return thunkAPI.rejectWithValue(error.response.data);
    }
})

// importErrorMsg
export const importErrorMsg:any=createAsyncThunk(
  'auth/resetErrorMsg',
  async(
    {
      externalOrderId,
      displayOrderId,
      integrationId,
      integrationTypeEnumId
    }:{
      externalOrderId:any
      displayOrderId:any
      integrationId:any
      integrationTypeEnumId:any
    },
  thunkAPI
  )=>{
    try{
      let token: any = localStorage.getItem("moquiSessionToken");
       console.log("integrationTypeEnumId",integrationTypeEnumId)
      const response= await axios.post(`/rest/s1/fp/order/reimportOrder`,
        {
          integrationId,
          ...(integrationTypeEnumId === "ItShipStation" ? { displayOrderId } : { externalOrderId })
        },
        {
        headers:{
            moquiSessionToken:token
          }
        })
        if(response.status==200){
             if(response.data.messages){
                notification["success"]({
                  message:"success",
                  description:response?.data?.messages
                })
            }
          }
      return response
    }catch(error:any){
       return thunkAPI.rejectWithValue(error.response.data);
    }
})



export interface AuthState {
  currTab: any;
  currSubScreen: String;
  isSuccess: boolean;
  isFetching: boolean;
  username: string;
  userId?:string
  email: string;
  isSidebarOpen: boolean;
  industryTypes: Array<any>;
  countriesList: Array<any>;
  statesList: Array<any>;
  shipCarriers: Array<any>;
  shipMethods: Array<any>;
  hasShipmentId: boolean;
  productStoresList: Array<any>;
  customersList: Array<any>;
  shippingAccountList: Array<any>;
  activeOrg: Array<any>;
  isChatBotEnable:boolean;
  chatBotApiKey:any
  activeOrgStores: Array<any>;
  activeStore: any;
  allStatesList: Array<any>;
}
// const selectedStore: any = localStorage.getItem("activeStore");
const initialState: AuthState = {
  currTab: window.location.href.split("/")[3],
  currSubScreen: "",
  isSuccess: false,
  isFetching: false,
  username: "",
  userId:localStorage.getItem("userId")??"",
  shipMethods: [],
  industryTypes: [],
  email: "",
  countriesList: [],
  statesList: [],
  shipCarriers: [],
  hasShipmentId: false,
  productStoresList: [],
  customersList: [],
  shippingAccountList: [],
  isSidebarOpen: localStorage.getItem("isNavOpen") === "true" ? true : false,
  activeOrg: [],
  isChatBotEnable:false,
  chatBotApiKey:"",
  activeOrgStores: [],
  activeStore: {},
  allStatesList: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isSuccess = false;
      state.isFetching = false;
      state.hasShipmentId = false;

      return state;
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getActiveOrg.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.activeOrg = payload.organization.organizationName;
        state.isChatBotEnable=payload.isChatBotEnable
        state.chatBotApiKey=payload.chatBotApiKey
      }
    );
    builder.addCase(getActiveOrg.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getActiveOrg.rejected, (state) => {
      state.isFetching = false;
      state.activeOrg = [];
    });
    builder.addCase(
      getStoreList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.activeOrgStores = payload;
      }
    );
    builder.addCase(getStoreList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getStoreList.rejected, (state) => {
      state.isFetching = false;
      state.activeOrgStores = [];
    });
    builder.addCase(
      getActiveStore.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.activeStore = payload;
      }
    );
    builder.addCase(getActiveStore.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getActiveStore.rejected, (state) => {
      state.isFetching = false;
      state.activeStore = {};
    });
    builder.addCase(getDashboardReports.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(getDashboardReports.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getDashboardReports.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(getSessionToken.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(getSessionToken.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getSessionToken.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.userId=""
    });
    builder.addCase(logout.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(logout.rejected, (state) => {
      state.isFetching = false;
      state.isSuccess = false;
    });
    builder.addCase(
      getGeoCountries.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.countriesList = payload;
      }
    );
    builder.addCase(getGeoCountries.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getGeoCountries.rejected, (state) => {
      state.isFetching = false;
      state.countriesList = [];
    });
    builder.addCase(
      getGeoStates.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.statesList = payload?.mappedArray;
        state.allStatesList = payload?.states;
      }
    );
    builder.addCase(getGeoStates.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getGeoStates.rejected, (state) => {
      state.isFetching = false;
      state.statesList = [];
    });
    builder.addCase(
      getShipCarriers.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.shipCarriers = payload;
      }
    );
    builder.addCase(getShipCarriers.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getShipCarriers.rejected, (state) => {
      state.isFetching = false;
      state.shipCarriers = [];
    });
    builder.addCase(
      getShipMethods.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.shipMethods = payload;
      }
    );
    builder.addCase(getShipMethods.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getShipMethods.rejected, (state) => {
      state.isFetching = false;
      state.shipMethods = [];
    });
    builder.addCase(
      getProductStoresList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.productStoresList = payload;
      }
    );
    builder.addCase(getProductStoresList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getProductStoresList.rejected, (state) => {
      state.isFetching = false;
      state.productStoresList = [];
    });
    builder.addCase(
      getCustomersList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.customersList = payload;
      }
    );
    builder.addCase(getCustomersList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getCustomersList.rejected, (state) => {
      state.isFetching = false;
      state.customersList = [];
    });
    builder.addCase(
      getShippingAccountList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.shippingAccountList = payload;
      }
    );
    builder.addCase(getShippingAccountList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(getShippingAccountList.rejected, (state) => {
      state.isFetching = false;
      state.shippingAccountList = [];
    });
    builder.addCase(
      createIncomingShipment.fulfilled,
      (state, { payload }: { payload: any }) => {
        if (payload.shipmentId) {
          state.hasShipmentId = true;
        }
        state.isFetching = false;
      }
    );
    builder.addCase(createIncomingShipment.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createIncomingShipment.rejected, (state) => {
      state.isFetching = false;
      state.hasShipmentId = false;
    });
    builder.addCase(downloadCsvTemplate.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(downloadCsvTemplate.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(
      downloadCsvTemplate.rejected,
      (state, { payload }: { payload: any }) => {
        console.log("get user id error payload", payload);
        state.isFetching = false;
      }
    );
    builder.addCase(
      industryTypeList.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.isFetching = false;
        state.industryTypes = payload;
      }
    );
    builder.addCase(industryTypeList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(industryTypeList.rejected, (state) => {
      state.isFetching = false;
      state.industryTypes = [];
    });
    builder.addCase(loginUser.fulfilled, (state,{payload}:{payload:any}) => {
      state.userId=payload?.userId
    });
  },
});

export const { toggleSidebar, clearState } = authSlice.actions;
export const userSelector = (state: any) => state.auth;
export default authSlice.reducer;
